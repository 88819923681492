<div class="video-outer">

  <post-text [text]="text" [expanded]="expanded"></post-text>

  <div class="video-wrapper" (click)="$event.stopPropagation()">
    <div *ngIf="isYoutube" class="youtube-container">  
      <youtube-player videoId="{{videoId}}" disablePlaceholder/>    
    </div>
    <div *ngIf="isVimeo">
      <div #vimeoContainer class='vimeo-container'></div>
    </div>
    <div *ngIf="isFacebook" class="facebook-container">  
      <iframe #facebookContainer (load)="resizeIframe($event)" width="100%" height="100%" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
    </div>
  </div>

</div>