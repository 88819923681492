import { WebPost } from './models';
import { htmlLink } from './models';
import * as Models from './models';

export const pageItemsExhibition: WebPost[] = [
            
            
            {
                title: 
                {
                    cs: "Všude rajče, sympozium Brno",
                    en: "Tomato everywhere, Brno symposium",
                    de: "Überall Tomaten, Symposium im Brünn",
                    
                },
                date: "10/2023",
                type: 'gallery',
                content: {
                    text:  {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/20230923_141710.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/20230923_141836.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/20230923_141956.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/20230923_142023.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/20230923_142118.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/IMG-20230922-WA0000.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/IMG-20230923-WA0001.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Brno_10-2023/IMG-20230923-WA0004.jpg',
                        },
                    ],
                },
            },
            {
                title: 
                {
                    cs: "Mezi veřejným a intimním: Galerie Kritiků, Palác Adria, Praha",
                    en: "Between Public and Intimate: Gallery of Art Critics, Palác Adria, Prague",
                    de: "Zwischen Öffentlich und Intim: Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                date: "29.6.-23.7. 2023",
                type: 'gallery',
                content: {
                    text:  {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-23.7.23_Between Public and Intimate/verni_01.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-23.7.23_Between Public and Intimate/verni_02.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-23.7.23_Between Public and Intimate/verni_03.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-23.7.23_Between Public and Intimate/verni_04.jpg',
                        },
                    ],
                },
            },

            {
                title: 
                {
                    cs: "Rájcování",
                    en: "Bohemian Paradise",
                    de: "Böhmischer Paradiesrausch",
                    
                },
                date: "5/2023",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Adam Ondra a protest proti zavezení lomu v Prosečnici",
                        en: "Adam Ondra and the protest against the opening of the quarry in Prosečnice",
                        de: "Adam Ondra und der Protest gegen die Eröffnung des Steinbruchs in Prosečnice",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Adam_protest_5-2023.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/doubi.jpg',
                        },
                        
                    ],
                },
            },

            
            {
                title: 
                {
                    cs: "Antropocén, Neviditelné změny: Front Room Gallery 727 Warren St, Hudson, NY, USA",
                    en: "Anthropocene, Invisible changes: Front Room Gallery 727 Warren St, Hudson, NY, USA",
                    de: "Anthropocene, Unsichtbare Veränderungen: Front Room Gallery 727 Warren St, Hudson, NY, USA",
                },
                date: "January 21- February 18, 2023",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Více na: " + htmlLink("https://new-york.czechcentres.cz/en/blog/2023/01/anthropocene-invisible-changes","Czech Center – New York / Exhibition Anthropocene – Invisible Changes") + "<br>"
                        + htmlLink("https://www.frontroomles.com/anthropocene", "Anthropocene &mdash; FRONT ROOM GALLERY"),
                        en: "More at: " + htmlLink("https://new-york.czechcentres.cz/en/blog/2023/01/anthropocene-invisible-changes","Czech Center – New York / Exhibition Anthropocene – Invisible Changes") + "<br>"
                        + htmlLink("https://www.frontroomles.com/anthropocene", "Anthropocene &mdash; FRONT ROOM GALLERY"),
                        de: "Mehr auf: " + htmlLink("https://new-york.czechcentres.cz/en/blog/2023/01/anthropocene-invisible-changes","Czech Center – New York / Exhibition Anthropocene – Invisible Changes") + "<br>"
                        + htmlLink("https://www.frontroomles.com/anthropocene", "Anthropocene &mdash; FRONT ROOM GALLERY"),
                        
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/NY_2023/NY_7.jpg',
                        },
                       
                        
                    ],
                },
            },

        

            {
                title: 
                {
                    cs: "Antropocén, Neviditelné změny: Galerie Kritiků, Palác Adria, Praha",
                    en: "Anthropocene, Invisible changes: Gallery of Art Critics, Palác Adria, Prague",
                    de: "Anthropocene, Unsichtbare Veränderungen: Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                 date: "30/6 - 24/7/2022",
                type: 'gallery',
                content: {
                    text:  {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_00.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_01.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_02.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_03.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_04.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_05.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_06.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_07.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_08.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_09.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_11.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_12.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_13.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_14.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/GK, 29.6.-27.7.22_Antropocen/A_15.jpg',
                        },
                    ],
                },
            },
            {
                title: 
                {
                    cs: "Pohyblivý obraz ve městě - Edita Pattová, Lucie Svobodová: Galerie Kritiků, Palác Adria, Praha",
                    en: "Moving Image in the City, Edita Pattová a Lucie Svobodová: Gallery of Art Critics, Palác Adria, Prague",
                    de: "Bewegtbild in der Stadt, Edita Pattová, Lucie Svobodová: Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                date: "12/5 - 26/5/2020",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Obraz,GK,_4-2020/pohyb_9.jpg',
                        },
                    ],
                },
            },

            {
                title:   {
                    cs: "Pohyblivý obraz ve městě - Edita Pattová, Lucie Svobodová: Galerie Kritiků, Palác Adria, Praha",
                    en: "Moving Image in the City, Edita Pattová a Lucie Svobodová: Gallery of Art Critics, Palác Adria, Prague",
                    de: "Bewegtbild in der Stadt, Edita Pattová, Lucie Svobodová: Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                date: "12/5 - 26/5/2020",
                type: 'video',
                content: {
                    text: {
                        cs: "<b>Výstava „Pohyblivý obraz ve městě“ - Galerie kritiků</b>"
                        + "<br>Připravili jsme se pro vás report naší aktuální výstavy „Pohyblivý obraz ve městě“."
                        + "<br>Lucie Svobodová - Edita Pattová",
                        en: "<b>Exhibition Moving Image in the City: Gallery of Art Critics</b>"
                        + "<br>We have prepared a report of our current exhibition Moving Image in the City for you."
                        + "<br>Lucie Svobodová - Edita Pattová",
                        de: "<b>Ausstellung Bewegtbild in der Stadt: Galerie der Kritiker</b>"
                        + "<br>Wir haben für Sie einen Bericht über unsere aktuelle Ausstellung Bewegtbild in der Stadt erstellt."
                        + "<br>Lucie Svobodová - Edita Pattová",
                    },
                    type: 'facebook',
                    videoId: '268294240880514',
                },
            },
            {
                title: 
                {
                    cs: "Neon Dreamer 2.0: Vzduch ve městě: Galerie Kritiků, Palác Adria, Praha",
                    en: "Neon Dreamer 2.0: City Air: Gallery of Art Critics, Palác Adria, Prague",
                    de: "Neon Dreamer 2.0: City Air: Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                date: "8/10/2019",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3472.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3521.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3529.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3530.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3531.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3545.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3550.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Vzduch,GK,_10-2019/_MG_3558.jpg',
                        },
                    ],
                },
            },
            {
                title: 
                {
                    cs: "Neon Dreamer art tour:"
                    + " 3. 8. 2017: Jane Richlovsky Gallery, 108 Cherry Street, Seattle, WA / 5. 8. 2017: Idea Fab Labs, Chico, CA"
                    + " 11. 8. 2017: Minna Gallery, San Francisco, CA / 12. 8. 2017: Kennedy Gallery, Sacramento, CA"
                    + " 14. 8. 2017: Neyborly Gallery, Berkeley, CA / 15. 8. 2017: Kaiwa Gallery, Santa Cruz, CA",
                    en: "Neon Dreamer art tour:"
                    + " 3/8/2017: Jane Richlovsky Gallery, 108 Cherry Street, Seattle, WA / 5. 8. 2017: Idea Fab Labs, Chico, CA"
                    + " 11/8/2017: Minna Gallery, San Francisco, CA / 12. 8. 2017: Kennedy Gallery, Sacramento, CA"
                    + " 14/8/2017: Neyborly Gallery, Berkeley, CA / 15. 8. 2017: Kaiwa Gallery, Santa Cruz, CA",
                    de: "Neon Dreamer art tour:"
                    + " 3. 8. 2017: Jane Richlovsky Gallery, 108 Cherry Street, Seattle, WA / 5. 8. 2017: Idea Fab Labs, Chico, CA"
                    + " 11. 8. 2017: Minna Gallery, San Francisco, CA / 12. 8. 2017: Kennedy Gallery, Sacramento, CA"
                    + " 14. 8. 2017: Neyborly Gallery, Berkeley, CA / 15. 8. 2017: Kaiwa Gallery, Santa Cruz, CA",
                    
                },
                date: "8/2017",
                type: 'photo',
                content: {
                    description: 
                    {
                        cs: "„Neon Dreamer“ je umělecký site-specific projekt s důrazem na pohyb ve všech podobách." 
                        + " Figury se pohybují po plátně, lidé se pohybují kolem malby a projekt samotný se pohyboval po Spojených Státech." 
                        + " Jezdily jsme od města k městu a v různých galeriích jsme vždy nainstalovaly plátna i s hrou." 
                        + "<br>"
                        + " Lidé, kteří chodili kolem, byli pozváni, aby si zahráli. Ti, kteří se zastavili, si mohli užít svobodu přítomnosti" 
                        + " a mohli se rozhlédnout  kolem a více si všímat krásy každodennosti.",
                        en: "Neon Dreamer is a site-specific art project focused on movement in all ways." 
                        + " The images move around the canvas, people move around the painting and the project itself moved throughout the United States." 
                        + "<br>"
                        + " We drove from town to town, setting up the art piece and game at various locations." 
                        + " People passing by were invited to play the game and for those that stop," 
                        + " the goal was to teach or reteach the gift of enjoying the present and become more conscious to look around and see the beauty that surrounds us in our daily routine.",
                        de: "Neon Dreamer ist ein ortsspezifisches Kunstprojekt, das sich in jeder Hinsicht auf Bewegung konzentriert."
                        + " Die Bilder bewegen sich auf der Leinwand, die Menschen bewegen sich auf dem Gemälde und das Projekt selbst bewegte sich durch die Vereinigten Staaten."
                        + "<br>"
                        + " Wir fuhren von Stadt zu Stadt und stellten das Kunstwerk und das Spiel an verschiedenen Orten auf."
                        + " Passanten wurden eingeladen, das Spiel zu spielen und diejenigen, die stehen blieben."
                        + " Ziel war es, die Gabe, die Gegenwart zu genießen, zu lehren oder wieder zu lehren und bewusster zu werden," 
                        + " um sich umzusehen und die Schönheit zu sehen, die uns in unserem Alltag umgibt.",
                        
                    },
                    src: '/assets/img/Vystavy/jane_8-2017.jpg',
                },
            },

            {
                title: 
                {
                    cs: "Samostatná výstava: Neon Dreamer, Galerie Prokopka, Děčín",
                    en: "Solo exhibition: Neon Dreamer, Gallery Prokopka, Děčín",
                    de: "Einzelausstellung: Neon Dreamer, Galerie Prokopka, Tetschen",
                    
                },
                date: "4.4. - 29.4. 2017",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0061.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0065.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0074.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0080.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0087.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0089.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Decin_4-2017/DSC_0093.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "Samostatná výstava: Neon Dreamer, Galerie Prokopka, Děčín",
                    en: "Solo exhibition: Neon Dreamer, Gallery Prokopka, Děčín",
                    de: "Einzelausstellung: Neon Dreamer, Galerie Prokopka, Tetschen",
                   
                },
                date: "4.4. - 29.4. 2017",
                type: 'video',
                content: {
                    text: {
                        cs: "Video z vernisáže",
                        en: "Video from the opening",
                        de: "Video von der Eröfnung",
                        
                    },
                    type: 'vimeo',
                    videoId: '239981816',
                },
            },
            
            {
                title: 
                {
                    cs: "43. World Gallery of Drawing - OSTEN, 43. International Biennial of Drawing, Skopje, MK",
                    en: "43. World Gallery of Drawing - OSTEN, 43. International Biennial of Drawing, Skopje, MK",
                    de: "43. World Gallery of Drawing - OSTEN, 43. International Biennial of Drawing, Skopje, MK",
                    
                },
                date: "23. 9. - 30. 9. 2016",
                type: 'gallery',
                content: {
                    text: 
                    {
                        cs: "Vyhrála jsem Osten Award za klasickou fotografii s kresbou na negativu ze série Rájcování.",
                        en: "I won an Osten Award for a silver-gelatin print with a drawing on the negative from the series Bohemian Paradise.",
                        de: "Ich habe die Osten Award für die Fotos mit der Zeichnung ans Negativbild von meiner Kollektion Böhmischer Paradiesrausch gewonnen.",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2016/Sk9.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "IV. Mezinárodní malířský plenér, Spišské Podhradie, SK",
                    en: "IV. International landscape painting symposium, Spišské Podhradie, Slovakia",
                    de: "IV. das internationale Pleinair, Spišské Podhradie, Slovakia",
                    
                },
                date: "11. 9. - 23. 9. 2016",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy v Mestskej Galérii",
                        en: "Photos from an exhibition in the City Gallery, Spišské Podhradie, Slovakia",
                        de: "Fotoos von der Ausstellung in der Stadtgalerie, Spišské Podhradie, Slovakia",
                       
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/D1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/D2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plener,SK_9-2016/plener7.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "Figura v Pohybu, Galerie Kritiků, Palác Adria, Praha",
                    en: "Figure in Motion, Gallery of Art Critics, Prague",
                    de: "Figure in Motion, Galerie der Kritiker, Adria Palast, Prag",
                    
                },
                date: "12. 7. - 24. 7. 2016",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Figura_v_pohybu,GK,_7-2016/K8.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "samostatná výstava: Změna účelu vozidla, Altán Klamovka, Praha 5",
                    en: "solo exhibition: Change of the vehicle purpose, Summerhouse Klamovka, Prague",
                    de: "Einzelausstellung: Veränderung des Fahrzeugzwecks, Altan Klamovka, Prag",
                    
                },
                date: "19. 8. 2015 - 20. 9. 2015",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Cyklus je autobiografickým zachycením reálné situace, kterou autorka musela řešit po nabourání vozidla. V rámci likvidace vraku"
                        + " se setkala s formulářem na ekologickou likvidaci, který ji svým názvem Změna účelu vozidla inspiroval a pojmenoval i její dílo." 
                        + "<br>"
                        + " Jde o to, že majitel vraku musí úřad přesvědčit o tom, že změnil jeho účel, tedy musí jej zdokumentovat a na základě jeho uznání jej úřad vyjme z evidence." 
                        + " V rámci tohoto procesu se Edita Pattová rozhodla vrak zhodnotit a přetvořit na umělecké dílo. Malovala přímo na černý povrch kapoty BMW. Černou metalízu využila nadvakrát." 
                        + " Poprvé při procesu malování, kdy přenesla část vraku do pokoje. Zde metalíza fungovala jako zrcadlo, na povrchu se odrážel interiér, světlo házelo stíny. Autorka se inspirovala touto změnou"
                        + " a zachytila realisticky vše, co viděla i sebe. Změnu výsledného obrazu přenesením do výstavního prostoru využívá Edita Pattová podruhé, kdy pracuje s odrazem dalšího prostoru." 
                        + " Divákovi se tak otevírají dva prostory. Jeden intimní ze života autorky a druhý přímo v galerii, ve kterém sám divák hraje určitou aktivní roli." 
                        + "<br>"
                        + "Text: Lenka Sýkorová - altán se nachází v parku Klamovka",
                        en: "This collection is autobiographic realization of real situation, which the author had to deal with after she crashed a car. Within"

                        + " the claim settlement she had to deal with the form for ecological disposal called Change of the vehicle purpose." 
                        + "<br>"
                        + " This name inspired her and she named the artwork after it." 
                        + " The point is, that the owner of the wreck must must convince the office of the change of its purpose. It means make a documentation of it and on this base the office takes it out of the register." 
                        + " Within the frame of this process, Edita Pattova decided to valorize"
                        + " the wreck. She painted directly on the black surface of the BMW engine bonnet. Its original surface is glazing like a mirror, so she installed the bonnet into her room and painted everything she saw reflected" 
                        + " in it including the reflection of herself. The bonnet undercoat is black metallic and shows through in the dark and black parts of the painting. When installed in a gallery the black metallic is also reflecting parts and lights of the surrounding." 
                        + " The spectator could see there than two reflections at once: an intimate one from the life of the author and the public one where he plays an active role partly." 
                        + "<br>"
                        + "Text: Lenka Sykorova - the summerhouse is placed in park Klamovka",
                        de: "Wenn man das Autowrack von der Evidenz herausnehmen will, muss man die Bestätigung über ökologische Liquidation vorlegen." 
                        + " Es gibt zweite Möglichkeit im Dekret “Veränderung des Fahrzeugzwecks”. Dazu bringt man gewöhnlich zum Amt Fotos,"
                        + " dass das Wrack wie Hühnerstall, Hundehütte, Werbeanzeiger und dergleichen dient. Ich entschied mich aus manchen Wrackteilen ein Kunstwerk zu schaffen." 
                        + "<br>"
                        + "Das Bild wurde mit Ölfarben direkt an die vordere Motorhaube gemalt. Die schwarze Farbe wurde mit schwarz Metallic der Unterlage gebildet." 
                        + " Die Oberfläche ist glänzend und in schwarzen Teilen reflektieren verschiedene Gegenstände und Lichter. Ich malte alles, was ich im Raum mitsamt mir sah." 
                        + " Wenn das resultierende Bild in einen anderen Raum kommt, wiederspiegelt sich dort das zweite Bild, so sieht man zwei Bilder in einem." 
                        + "<br>"
                        + "Text: Lenka Sýkorová - Klamovka",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K1 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K2 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K3 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K4 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K5 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K6 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K7 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K8 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Klamovka_8-2015/K9.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "42. World Gallery of Drawing - OSTEN, 42. Mezinárodní bienále kresby, Skopje v Galerii Kritiků, Palác Adria, Praha 1",
                    en: "42. World Gallery of Drawing - OSTEN, 42. International Biennial of Drawing, Skopje in a Gallery of Art Critics, Adria Palace, Prague 1",
                    de: "42. World Gallery of Drawing - OSTEN, 42. Biennale der Zeichnung und Grafik, Skopje 2014 in der Galerie der Kritiker, Adria Palast, Prag 1",
                    
                },
                date: "18. 8. 2015 - 13. 9. 2015",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Muzeum kresby a grafiky ve Skopje - World Gallery of Drawing in Skopje - vzniklo v roce 1945, takže letos slaví už 70 let své činnosti a je jediným svého druhu na světě." 
                        + " Výběrová výstava v Galerii Kritiků, nazvaná jasně a jednoznačně - Osten - reflektuje tedy letošní"
                        + " již 42. Mezinárodní bienále kresby ve Skopje. Je to výstava kreseb a umění na papíře, jak by mohl znít i podtitul, a zahrnuje díla 30 vybraných umělců ze zahraničí a dva české tvůrce (Edita Pattová a Filip Buryan)." 
                        + "<br>"
                        + "A jednotlivé práce? No, to už je věc každého z návštěvníků, co je jeho přijímání umění bližší, ale na pár vystavujících si dovolíme upozornit i my, poučení laici. Začněme hrdě a vlastenecky od Edity Pattové." 
                        + " Její cyklus nazvaný Rájcování (kombinovaná technika - fotografie a kresba na papíře) přinesla vtip a nadsázku, ve formě dialogu neviděných horolezců a skal, na jejichž vrcholy se derou." 
                        + " Drsný humor tvrdých lezců se mísí s fantazijními odpověďmi a průpovídkami slézaných skal." 
                        + "<br>"
                        + "Zkráceno dle: Richarda Koníčka " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                        en: "World Gallery of Drawing in Skopje was founded in 1945, it celebrates therefore already 70 years of its activity and it is the only one of its kind in the world." 
                        + " The exhibition in the Gallery of Art Critics named simply OSTEN reflects this year's 42. annual of International Biennial of Drawing in Skopje. It is an exhibition of drawings" 
                        + " and art on paper and there are exhibited artworks from 30 selected international artists and two Czech ones (Edita Pattova and Filip Buryan)." 
                        + "<br>"
                        + "What about individual works? It is a decision of visitors what they like more or less, but I would like to point out few artists anyway." 
                        + " Let's start proudly and patriotically from Edita Pattova. Her collection called Bohemian Paradise (silver-gelatin print with a drawing"
                        + " on the negative) brought us joke and hyperbole in the form of dialog between unseen climbers and rocks on whose summits they climb. Rough humor of tough climbers is mixed with fantasized answers and sayings of the rock towers on which they climb." 
                        + "<br>"
                        + "Shortened from: Richard Konicek " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                        de: "Das Museum der Zeichnung und Grafik - World Gallery of Drawing in Skopje - wurde im Jahre 1945 entstanden, also dieses Jahr feiert es schon 70 Jahre seiner Tätigkeit." 
                        + " Es ist ganz einzig in seiner Art in der Welt. Eine Auswahlausstellung in der Galerie der Kritiker, ganz klar Osten genannt, representiert schon heuriger 42. Jahrgang." 
                        + " Es ist die Ausstellung der Zeichnung und Kunst auf Papier und begreift Kunstwerke von 30 ausländischen und zwei tschechischen Künstlern (Edita Pattova und Filip Buryan)." 
                        + "<br>"
                        + "Und einzelne Kunstwerke? Das ist schon die Sache von allen Besuchern, was ihnen näher ist. Auf einige Künstler erlauben auch wir, Laien, aufmerksam zu machen." 
                        + " Beginnen wir stolz und patriotisch von Edita Pattova. Ihr Zyklus Böhmischer Paradiesrausch (Photographie im Rahmen, die Zeichnung ans Negativbild) brachte Witz und "
                        + " Übertreibung in der Dialogform zwischen ungesehenen Kletterern und Felsen, auf ihre Gipfel sie klettern." 
                        + " Rauer Humor harter Kletterer wird mit fantasierten Antworten und Sprüchen gekletterten Felsen gemischt." 
                        + "<br>"
                        + "Verkürzt nach: Richard Konicek " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O11.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O12.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O13.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Praha_9-2016/O9.jpg',
                        },
                    ],
                },
            },
            
            
            {
                title: {
                    cs: "samostatná výstava: Facebook, Galerie Fotografic, Praha 1",
                    en: "Solo exhibition: Facebook, Gallery Fotografic, Prague 1",
                    de: "Einzelausstellung: Facebook, Galerie Fotografic, Prag 1",
                   
                },
                date: "21. 10. - 7. 12. 2014",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Jak prezentujeme svou osobnost a život na veřejnosti? Chováme se jinak online a jinak v „reálném“ světě? A co je vlastně „reálný“ svět?" 
                        + " Vždyť na internetu si naše příspěvky a komentáře také prohlížejí „reální“ existující lidé.." 
                        + "<br>"
                        + "Projekt Edity Pattové „Facebook“ zkoumá fenomén tohoto média a sebeprezentace. Co o nás vypovídá náš profil? Většinou sdílíme"
                        + " jen pozitivní pocity a úspěchy. Fotografie, které nám připadají vtipné, kde vypadáme sexy, vytváříme si tak svého lepšího avatara." 
                        + " Nejde o kritiku tohoto média, jde spíše o sociální průzkum, koneckonců autorka je také uživatel. Edita namalovala tato plátna podle reálných fotografií" 
                        + " a chatů na Facebooku, tím tyto příspěvky vrátila zpět do „reálného“ světa a některé reakce překvapily." 
                        + " Někteří požadovali změnu uživatelského jména, jiní zase měli radost, že se stanou součástí uměleckého díla.." 
                        + "<br>"
                        + "text: Martin Fojtek, Galerie Fotografic" + "<br>"
                        + htmlLink("https://fotografic.cz/project/edita-pattova/", "Edita Pattová | Facebook | 21. 10. – 7. 12. 2014 - Galerie Fotografic") + "<br>"
                        + htmlLink("https://ct24.ceskatelevize.cz/clanek/kultura/edita-pattova-na-facebooku-lajk-339740", "Edita Pattová na Facebooku. Lajk!"),
                        en: "How do we present our personalities and lives in public? Do we behave differently online and in the real world? And what exactly"
                        + " is the real world? Our contributions or comments on Facebook are also read by real people after all.." 
                        + "<br>"
                        + "The Edita's Facebook project examines the phenomenon of this medium and self-presentation." 
                        + " What exactly our profile tells about us? We mostly share only positive feelings or successes, photos which are either funny or sexy," 
                        + " we tend to create our better avatar. It is not about critics however, it is rather a social study; Edita is also an active Facebook user after all." 
                        + " She has painted these canvases according to real photos and chats on Facebook, thus bringing them back to the real world. And some reactions have been rather surprising." 
                        + " Some asked to change their username, others were pleased by becoming a part of this artwork.." 
                        + "<br>"
                        + "text: Martin Fojtek, Gallery Fotografic," + "<br>" 
                        + htmlLink("https://fotografic.cz/project/edita-pattova/", "Edita Pattová | Facebook | 21. 10. – 7. 12. 2014 - Galerie Fotografic") + "<br>"
                        + htmlLink("https://ct24.ceskatelevize.cz/clanek/kultura/edita-pattova-na-facebooku-lajk-339740", "Edita Pattová na Facebooku. Lajk!"),
                        de: "Wie präsentieren wir unsere Persönlichkeit und Leben in der Öffentlichkeit? Unterscheiden wir zwischen online und der realen Welt? Was genau ist die reale Welt?" 
                        + " Unsere Beiträge und Kommentare auf Facebook werden trotzdem immer noch von realen Personen gelesen." 
                        + "<br>"
                        + "Das Edita`s Facebook Projekt prüft das Phänomen dieses Mediums und der Eigendarstellung. Was sagen die Profile genau über uns aus?" 
                        + " Meistens teilen wir nur positive Gefühle oder Erfolge, lustige oder sexy Bilder, wir neige dazu unser besseres Avatar"
                        + " (oder virtuelles Ich) zu erschaffen. Es geht nicht um die Kritik, es soll vielmehr eine Sozialstudie sein; Edita ist trotzdem immer noch ein aktiver Facebook Benutzer." 
                        + " Sie hat die Gemälde nach realen Bilder und Chats auf Facebook gezeichnet um sie in die reale Welt zurückzubringen. Einige Reaktionen waren sehr überraschend." 
                        + " Einige habe aufgefordert, dass ihr Benutzernamen geändert werden, andere waren erfreut ein Teil von diesem Kunstwerk zu werden." 
                        + "<br>"
                        + "text: Martin Fojtek, Galerie Fotografic," + "<br>" 
                        + htmlLink("https://fotografic.cz/project/edita-pattova/", "Edita Pattová | Facebook | 21. 10. – 7. 12. 2014 - Galerie Fotografic") + "<br>"
                        + htmlLink("https://ct24.ceskatelevize.cz/clanek/kultura/edita-pattova-na-facebooku-lajk-339740", "Edita Pattová na Facebooku. Lajk!"),
                       
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Fotografic_10-2014/edita ponorama 4 mensi.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Fotografic_10-2014/edita ponorama mensi.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Fotografic_10-2014/Fotografic_panorama.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "samostatná výstava: Facebook, Galerie Fotografic, Praha 1",
                    en: "Solo exhibition: Facebook, Gallery Fotografic, Prague 1",
                    de: "Einzelausstellung: Facebook, Galerie Fotografic, Prag 1",
                   
                },
                date: "21. 10. - 7. 12. 2014",
                type: 'video',
                content: {
                    text: {
                        cs: "Fotky z vernisáže",
                        en: "Photos from the opening",
                        de: "Fotos von der Eröfnung",
                        
                    },
                    type: 'vimeo',
                    videoId: '374155808',
                },
            },
            {
                title: {
                    cs: "OSTEN: 42. Mezinárodní bienále kresby Skopje 2014, Makedonie",
                    en: "OSTEN BIENNIAL OF DRAWING Skopje 2014, Macedonia",
                    de: "OSTEN: 42. Biennale der Zeichnung und Grafik Skopje 2014, Mazedonien",
                    
                },
                date: "18. 9. 2014 - 31. 11. 2014",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Evoluce kresby je v přímé souvislosti s rozvojem uměleckých nástrojů a technik a s rozvojem společnosti jako takové." 
                        + " Dnes může být „kresba“ téměř čímkoli, čím umělec chce aby byla... a my to respektujeme! 1416 přihlášených prací zaslaných 475 umělci z 53 zemí"
                        + " z celého světa svědčí o úspěchu letošního Bienále kresby-Skopje 2014! Komise vybrala z každé země několik umělců k účasti"
                        + " na výstavě a jejich práce se také stanou součástí sbírky v našem „Muzeu Kresby“. Z České Republiky to je Edita Pattová a ze starší generace Adolf Born a Jiří Anderle." + "<br>"
                        + " Zkráceno podle textu v katalogu od Mice Jankulovski (Ředitele galerie OSTEN)" 
                        + "<br>"
                        + "Vystavila jsem zde soubor „Automyšlenky“, kresby ve fotografii díky podpoře „Galerie Kritiků“ v Praze."
                        + " Galerie OSTEN mě hostila v Makedonii, takže jsem tam s nimi strávila hezkých pár dní. Díky ",
                        en: "The evolution of drawing is in direct parallel with the development of art tools and techniques, and the development of society"
                        + " in general. Today, drawing can be almost anything that the artist wants it to be... and we respect that! The received 1416 works, sent"
                        + " by 475 artists from 53 countries around the world, show the success of this year's Biennale of Drawing-Skopje 2014!" 
                        + " The jury chose few artists from each country to participate at the exhibition and become part of the Museum of Drawing collection." 
                        + " From Czech Republic it was Edita Pattova and from the older generation Adolf Born and Jiri Anderle." + "<br>"
                        + " Shortened text from the catalogue by Mice Jankulovski (Director of OSTEN gallery)" 
                        + "<br>"
                        + "I exhibited the collection Autothoughts, drawings in photographs thanks to the support of the Gallery of Art Critics, Prague."
                        + " The OSTEN gallery hosted me in Macedonia, so I spent nice time with them. Thanks.",
                        de: "Die Entwicklung der Zeichenkunst geht Hand in Hand mit der Gesellschaftsentwicklung im Allgemeinen und der Entwicklung"
                        + " der Fertigkeiten und Techniken in der Kunst. Heutzutage kann fast alles eine Zeichnung sein, sofern der Künstler dies möchte....und dies wird respektiert!" 
                        + " Die erhaltenen 1416 Werke von 475 Künstlern aus 53 Ländern, zeigen den Erfolg der diesjährigen OSTEN BIENNIAL OF DRAWING Skopje 2014! Die Jury wählte aus jedem Land einige wenige Künstler aus," 
                        + " welche an der Ausstellung teilgenommen haben und deren Kunstwerke Teil der Museum der Zeichnung geworden sind. Aus der Tschechischen Republik wurden Edita Pattova" 
                        + " sowie von der älteren Generation Adolf Born und Jiri Anderle eingeladen." + "<br>"
                        + " Verkürzt von Mice Jankulovski (Direktor der Osten Galerie)" 
                        + "<br>"
                        + "Ich habe die Kollektion Autothoughts, Zeichnungen auf Grundlage von Fotografien ausgestellt. Für die Unterstützung möchte ich mich bei der Gallery of Art Critics," 
                        + " Prag herzlich bedanken. Die OSTEN Galerie hat mich in Mazedonien eingeladen und ich habe dort eine schöne Zeit verbracht. Vielen Dank. ",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/S9.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/Sk1 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/Sk2 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/Sk3 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/Sk4 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Osten,Skopje_9-2014/Sk5 (1).jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "Výstava Forum Junge Kunst: Bayern - Tschechien 2013 v Městské galerii Leerer Beutel, Bertoldstrasse 9, Regensburg, Německo",
                    en: "Exhibition Forum Junge Kunst: Bayern - Tschechien 2013, City Gallery in Leerer Beutel, Bertoldstrasse 9, Regensburg, DE",
                    de: "Ausstellung Forum Junge Kunst: Bayern - Tschechien 2013, in der Städtischen Galerie im Leeren Beutel, Bertoldstrasse 9, Regensburg",
                    
                },
                date: "13. 12. 2013 - 9. 2. 2014",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Výstavě Fórum mladé umění předcházela veřejná výzva na účast v projektu, na kterou se přihlásilo více než 127 umělců do 35 let." 
                        + " Osmičlenná porota, složená z českých a bavorských odborníků vybrala 25 mladých talentů, kteří společně vystavili své práce poprvé v červnu v Galerii města Plzně." 
                        + " Od zahájení dne 13.12.13 je výstava nabízející mnohovrstevnatý pohled na současnou českou a bavorskou mladou uměleckou tvorbu k vidění v Městské galerii v kulturním centru Leerer Beutel v Regensburku." + "<br>"
                        + " Zkráceno dle: www.bbkult.net - Centrum Bavaria Bohemia",
                        en: "There was a public call before this exhibition, where sent 127 young artists until the age of 35 their works up. A commission made"
                        + " of eight Czech and Bavarian art critics chose 25 talented artists, who exhibited their works together first in June in a City Gallery Plzen." 
                        + " Since the opening on 13. 12. 2013 is an exhibition offering multilayered view on contemporary czech and bavarian young artistic works possible to see in a City Gallery in Leerer Beutel, Regensburg." + "<br>"
                        + " Shortened from: www.bbkult.net - Centrum Bavaria Bohemia",
                        de: "Der Ausstellung Forum Junge Kunst ging ein öffentlicher Teilnahmeaufruf voraus, auf den hin sich mehr als 127 Künstler bis 35 Jahre für das Projekt bewarben." 
                        + " Eine achtköpfige Jury, der bayerische und tschechische Kunstexperten angehörten, wählte 25 junge Talente aus. Diese präsentierten ihre Werke im Juni erstmal gemeinsam in der Galerie der Stadt Pilsen." 
                        + " Seit der Eröffnung am 13.12.13 ist die Ausstellung, die einen vielschichtigen Einblick in die junge bayerische und tschechische Kunstszene bietet,"
                        + " in der Städtischen Galerie im Kulturzentrum Leerer Beutel in Regensburg zu sehen." + "<br>" 
                        + " Verkürzt nach: www.bbkult.net - Centrum Bavaria Bohemia",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R11.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Regensburg_12-2013/R9.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "samostatná výstava:  Arte in Movimento, Centro Ceco, Milan, Itálie",
                    en: "solo exhibition: Arte in Movimento, Centro Ceco, Milan, IT",
                    de: "Einzelausstellung:  Arte in Movimento, Centro Ceco, Milan, Italien",
                    
                },
                date: "30. 11. 2013 - 31. 12. 2013",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Centro_Ceco_11-2013/Centro_Ceco_1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Centro_Ceco_11-2013/Centro_Ceco_2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Centro_Ceco_11-2013/ja_a_pozvanka_milano.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "IX. Mezinárodní bienále současného umění, Fortezza da Basso, Firenze, Itálie",
                    en: "IX. International biennial of contemporary art, Fortezza da Basso, Firenze, IT",
                    de: "IX. International biennial of contemporary art, Fortezza da Basso, Firenze, Italien",
                    
                },
                date: "30. 11. 2013 - 8. 12. 2013",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Etika: DNA umění má podle kurátora Rolanda Belliniho, italského historika a kritika umění, vyjadřovat význam umění jakožto jediné etické existence v éře kapitalismu." 
                        + " Mezinárodní bienále současného umění ve Florencii se koná vždy v prvním prosincovém týdnu kalendářního roku v historické pevnosti Fortezza da Basso." 
                        + " V roce 2013 vystavovalo a bylo hodnoceno celkem 450 umělců z celého světa. Naše Edita Pattová jako jediná česká účastnice, vyslaná do Florencie za podpory MKČR a Sdružení výtvarných kritiků a teoretiků," 
                        + " získala v kategorii instalace diplom za čtvrté místo. Jeji Africký tygr, olejomalba na čelním skle auta se světelnou a pohybovou projekcí znamená esteticko-etickou záměnu skutečnosti a fikce." + "<br>" 
                        + " Zkráceno dle Vlasty Čihákové - Noshiro (členky vědecké rady bienále)",
                        en: "Ethics: DNA of Art should, due to a meaning of Italian art critique and historian Rolando Bellini, express the importance of art as"
                        + " the only ethical existence in the era of capitalism. International biennial of contemporary art in Firenze takes part every second year"
                        + " in the first week in December in the historical fortress Fortezza da Basso. In 2013 exhibited there and were evaluated 450 artists"
                        + " from the whole world altogether. Our Edita Pattová as the only Czech participant, sent to Firenze with support of MKČR and"
                        + " the Association of art critiques and theorists, gained a diploma for a 4. place in the Installation category. Her African tiger,"
                        + " oil on windshield of a vehicle with light and kinetic projection means aesthetically - ethical interchange between reality and fiction." + "<br>" 
                        + " Shortened text by: Vlasta Čiháková - Noshiro (academic councillor of the Biennial)",
                        de: "Etik: DNA Kunst soll nach dem italienischen Kunstkritiker und Historiker Rolando Bellini eine Kunstbedeutung wie einzige etische Existenz in der Kapitalismuszeit ausdrücken. Die internationale Biennale der gegenwärtigen Kunst in Firenze findet immer"
                        + " in der ersten Woche im December in einer historischen Festung Fortezza da Basso statt. In 2013 stellten dort insgesamt"
                        + " 450 Künstler aus der ganzen Welt aus. Unsere Edita Pattová wie einzige tschechische Teilnehmerin, mit der Unterstützung MKČR und Assoziation der Kunstkritiker und Theoretiker geschickt, erhielt in der Kategorie Installation ein Diplom für die vierte Stelle."
                        + " Ihr Afrikanischer Tiger, Öl auf Windschutzscheibe des Fahrzeugs mit Licht - und Bewegungsprojektion bedeutet ein estetischer und etischer Wechsel der Wirklichkeit und Fiktion." + "<br>" 
                        + " Verkürzt nach Vlasta Čiháková - Noshiro",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F11.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F12.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F13.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F14.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F15.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F16.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F17 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F18.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F19.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F20.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F21.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F22.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F23.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Firenze_11-2013/F9.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    cs: "Forum Junge Kunst: Bayern - Tschechien 2013, Městská Galerie, Plzeň",
                    en: "Forum Junge Kunst: Bayern - Tschechien 2013, City Gallery, Plzen",
                    de: "Forum Junge Kunst: Bayern - Tschechien 2013, Galerie der Stadt Pilsen",
                    
                },
                date: "14. 6. 2013 - 11. 7. 2013",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Výstavě Fórum mladé umění předcházela veřejná výzva na účast v projektu, na kterou se přihlásilo více než 127 umělců do 35 let." 
                        + " Osmičlenná porota, složená z českých a bavorských odborníků vybrala 25 mladých talentů, kteří společně vystavili své práce poprvé v červnu v Galerii města Plzně." 
                        + " Od zahájení dne 13.12.13 je výstava nabízející mnohovrstevnatý pohled na současnou českou a bavorskou mladou uměleckou tvorbu k vidění v Městské galerii v kulturním centru Leerer Beutel v Regensburku." + "<br>" 
                        + " Zkráceno dle: www.bbkult.net - Centrum Bavaria Bohemia",
                        en: "There was a public call before this exhibition, where sent 127 young artists until the age of 35 their works up. A commission made"
                        + " of eight Czech and Bavarian art critics chose 25 talented artists, who exhibited their works together first in June in a City Gallery Plzen." 
                        + " Since the opening on 13. 12. 2013 is an exhibition offering multilayered view on contemporary czech and bavarian young artistic works possible to see in a City Gallery in Leerer Beutel, Regensburg." + "<br>"
                        + " Shortened from: www.bbkult.net - Centrum Bavaria Bohemia",
                        de: "Der Ausstellung Forum Junge Kunst ging ein öffentlicher Teilnahmeaufruf voraus, auf den hin sich mehr als 127 Künstler"
                        + " bis 35 Jahre für das Projekt bewarben. Eine achtköpfige Jury, der bayerische und tschechische Kunstexperten angehörten, wählte 25 junge Talente aus." 
                        + " Diese präsentierten ihre Werke im Juni erstmal gemeinsam in der Galerie der Stadt Pilsen. Seit der Eröffnung"
                        + " am 13.12.13 ist die Ausstellung, die einen vielschichtigen Einblick in die junge bayerische und tschechische Kunstszene bietet,"
                        + " in der Städtischen Galerie im Kulturzentrum Leerer Beutel in Regensburg zu sehen." + "<br>"
                        + " Verkürzt nach: www.bbkult.net - Centrum Bavaria Bohemia",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Plzen_6-2013/P7.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "6. Cena Kritiky, Galerie Kritiků, Palác Adria, Praha",
                    en: "6. Award of Art Critics, Gallery of Art Critics, Adria Palace, Prague",
                    de: "6. Award of Art Critics, Galerie der Kritiker, Adria Palast, Prag",
                },
                date: "31. 1. 2013 - 24. 2. 2013",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },
        
            {
                title: {
                    cs: "From Different Corners-Práce na papíře, Galerie Kritiků, Palác Adria, Praha",
                    en: "From Different Corners-Works on paper, Gallery of Art Critics, Palác Adria, Prague",
                    de: "From Different Corners-Arbeits auf papier, Galerie der Kritiker, Adria Palast, Prag",
                },
                date: "22. 8. 2012 - 16. 9. 2012",
                type: 'gallery',
                content: {
                    text: {
                        cs: "From different corners je programatický název mezinárodní výstavy, který vyjadřuje trend vzrůstající spolupráce mezi galeriemi současného umení v Evropě." 
                        + " Výstava byla zahájena v Galerii Arteversum v Düsseldorfu na jaře 2011 a na podzim 2012 výstavou"
                        + " v Praze svou pout zakončuje. Základem jsou práce na papíře kmenových umělců zúčastněných galerií. Záměrem je vytvořit network pro jejich budoucí spolupráci."  + "<br>" 
                        + " Zkráceno dle Vlasty Čihákové - Noshiro (kurátorky výstavy)",
                        en: "From different corners is on one hand a programmatic title of an international exhibition, but also a growing, European - wide cooperation of galleries." 
                        + " The initially formal background are paper works by artists from the participating galleries. The exhibition moves to every gallery, starting in Düsseldorf." 
                        + " From different corners is a network of contemporary art and artists, which is each time directed by one of participating galleries." + "<br>" 
                        + " Shortened after Vlasta Čiháková - Noshiro (curator)",
                        de: "Aus unterschiedlichen Ecken ist einerseits ein programmatischer Titel einer internationalen Ausstellung, aber auch eine wachsende, europaweite Kooperation von Galerien."
                        + " Formeller Hintergrund sind zunächst Papierarbeiten von Künstlern der beteiligten Galerien. Die Ausstellung wandert in jede Galerie, beginnend in Düsseldorf."
                        + " Aus verschiedenen Ecken entsteht ein Netzwerk zeitgenössischer Kunst und Künstler, das jeweils von einer der teilnehmenden Galerien geleitet wird." + "<br>"
                        + " Gekürzt nach Vlasta Čiháková - Noshiro (Kurator)",
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Automyslenky,GK_8-2012/A1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Automyslenky,GK_8-2012/A2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Automyslenky,GK_8-2012/A3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Automyslenky,GK_8-2012/A4.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "From Different Corners-Práce na papíře, Galerie Chantal Bamberger, Strasbourg, Francie",
                    en: "From Different Corners-Works on paper, Gallery Chantal Bamberger, Strasbourg, France",
                    de: "From Different Corners-Arbeits auf papier, Galerie Chantal Bamberger, Strasbourg, Frankreich",
                },
                date: "23. 6. 2012 - 21. 7. 2012",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "From Different Corners-Práce na papíře, Galerie Bäckerstrasse 4, Vienna, Rakousko",
                    en: "From Different Corners-Works on paper, Gallery Bäckerstrasse 4, Vienna, Austria",
                    de: "From Different Corners-Arbeits auf papier, Galerie Bäckerstrasse 4, Vienna, Austria",
                },
                date: "16. 4. 2012 - 11. 5. 2012",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "From Different Corners-Práce na papíře, Galerie Bart Kunst in Huis, Nijmegen, Nizozemí",
                    en: "From Different Corners-Works on paper, Galerie Bart Kunst in Huis, Nijmegen, Netherlands",
                    de: "From Different Corners-Arbeits auf papier, Galerie Bart Kunst in Huis, Nijmegen, Netherlands",
                },
                date: "18. 2. 2012 - 7. 4. 2012",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "ESSL Art Award, Staroměstská Radnice, Praha",
                    en: "ESSL Art Award, Staroměstská Radnice, Prague",
                    de: "ESSL Art Award, Staroměstská Radnice, Prag",
                },
                date: "31. 5. 2011 - 26. 6. 2011",
                type: 'message',
                content: {
                    message: htmlLink("https://www.ceskatelevize.cz/porady/10318911545-kultura-s-dvojkou/211542156000108/", "1. června 2011 - Kultura s Dvojkou | Česká televize"),
                }
            },

            {
                title: {
                    cs: "From Different Corners-Práce na papíře, Galerie Arteversum, Düsseldorf, Německo",
                    en: "From Different Corners-Works on paper, Galerie Arteversum, Düsseldorf, Germany",
                    de: "From Different Corners-Arbeits auf papier, Galerie Arteversum, Düsseldorf, DE",
                },
                date: "16. 4. 2011 - 30. 6. 2011",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Nečekaný výběr pana Jahody, V. Kolona, p. l. Bohnice, Praha",
                    en: "Unexpected Selection by Mr. Strawberry, V. Kolona, p. l. Bohnice, Prague",
                    de: "Die unerwartete Auswahl des Herrn Jahoda, V. Kolona, Prag",
                },
                date: "27/5/2010 - 22/6/2010",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Hloupé a chytré, Galerie Kritiků, Palác Adria, Praha",
                    en: "Foolish and Ingenious, Gallery of Art Critics, Palác Adria, Prague",
                    de: "Dumm und schlau, Galerie der Kritiker, Adria Palast, Prag",
                },
                date: "19/5/2010 - 13/6/2010",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Hloupe a chytre,GK_5-2010/V7.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "V Praze je blaze, Galerie Kritiků, Palác Adria, Praha",
                    en: "Prague Is Bliss, Gallery of Art Critics, Palác Adria, Prague",
                    de: "In Prag ist gesegnet, Galerie der Kritiker, Adria Palast, Prag 1",
                },
                date: "19/5/2008 - 14/6/2008",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Nekonečná malba, Galerie Školská 28, Praha",
                    en: "Infinite Painting, Galerie Školská 28, Prague",
                    de: "Unendliches Bild, Galerie Školská 28, Prag",
                    
                },
                date: "10/3/2008 - 28/3/2008",
                type: 'video',
                content: {
                    text: {
                        cs: "Video z výstavy",
                        en: "Video from the exhibition",
                        de: "Video von der Ausstellung",
                        
                    },
                    type: 'vimeo',
                    videoId: '176178044',
                },
            },

            {
                title: {
                    cs: "Al Bin Trafin, Trafačka, Praha",
                    en: "Al Bin Trafin, Trafačka, Prague",
                    de: "Al Bin Trafin, Trafačka, Prag 1",
                },
                date: "12/5/2007",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Vlasta a Šárka (Dívčí válka aneb Gender Study), Konzum 1.patro, Brandýs nad Orlicí",
                    en: "Vlasta a Šárka (Girls War Quasi Gender Study), Konzum 1.patro, Brandýs nad Orlicí",
                    de: "Vlasta a Šárka (Mädchenkrieg oder Gender Study), Konzum, Brandýs nad Orlicí",
                },
                date: "9/9/2006 - 17/9/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Vlasta a Šárka (Dívčí válka aneb Gender Study), Galerie Kritiků, Palác Adria, Praha",
                    en: "Vlasta a Šárka (Girls War Quasi Gender Study), Gallery of Art Critics, Prague",
                    de: "Vlasta a Šárka (Mädchenkrieg oder Gender Study), Galerie der Kritiker, Prag",
                },
                date: "17/8/2006 - 27/8/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Kajmak Kajmak, Galerie Kritiků, Palác Adria, Praha",
                    en: "Kajmak Kajmak, Gallery of Art Critics, Palác Adria, Prague",
                    de: "Kajmak Kajmak, Galerie der Kritiker, Adria Palast, Prag 1",
                },
                date: "31/5/2006 - 12/6/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Videokemp - jednodenní přehlídka českého videoartu, Park Klamovka, Praha",
                    en: "Videokemp - one day show of contemporary Czech videoart, Park Klamovka, Prague",
                    de: "Videokemp - Festival der gegenwärtigen tschechischen Videokunst, Klamovka Park, Prag",
                },
                date: "21/5/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "H+B=VSL, Rathaus Gallery, Grimma, Německo",
                    en: "H+B=VSL, Rathaus Gallery, Grimma, Germany",
                    de: "H+B=VSL, Rathaus Gallery, Grimma, DE",
                    
                },
                date: "6/5/2006 - 10/6/2006",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Grimma_5-2006/G1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Grimma_5-2006/G2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Grimma_5-2006/G3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Grimma_5-2006/G4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Grimma_5-2006/G5.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    cs: "Pod Čarou, Experimental prostor NoD, Praha",
                    en: "Rejected, Experimental space NoD, Prague",
                    de: "Unter dem Strich, Experimentalraum NoD, Prag",
                },
                date: "19/1/2006 - 23/1/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Luxus, Club Alternatiff, Praha",
                    en: "Luxury, Club Alternatiff, Prague",
                    de: "Luxury, Club Alternatiff, Prag",
                },
                date: "15/12/2005 - 15/1/2006",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Play!, Gallery Doubner, Praha",
                    en: "Play!, Gallery Doubner, Prague",
                    de: "Play!, Gallery Doubner, Prag",
                    
                },
                date: "1/8/2005 - 14/8/2005",
                type: 'gallery',
                content: {
                    text: {
                        cs: "Fotky z výstavy",
                        en: "Photos from the exhibition",
                        de: "Fotos von der Ausstellung",
                        
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D1 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D10.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D11.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D12.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D2 (1).jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D8.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Vystavy/Doubner_8-2005/D9.jpg',
                        },
                    ],
                },
            },

            {
                title: "Videokemp",
                date: "21/5/2006",
                type: 'video',
                content: {
                    text: {
                       
                        cs: "Videokemp - jednodenní přehlídka českého videoartu, Park Klamovka, Praha",
                        en: "Videokemp - one day show of contemporary Czech videoart, Park Klamovka, Prague",
                        de: "Videokemp - Festival der gegenwärtigen tschechischen Videokunst, Klamovka Park, Prag",
                    },
                    type: 'vimeo',
                    videoId: '176025618',
                },
            },

            {
                title: {
                    cs: "Intimita, Klub Alternatiff, Praha",
                    en: "Intimacy, Club Alternatiff, Prague",
                    de: "Intimität, Klub Alternatiff, Prag",
                },
                date: "15/12/2004 - 15/1/2005",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Sprcha, Klub Alternatiff, Praha",
                    en: "Shower, Club Alternatiff, Prague",
                    de: "Dusche, Klub Alternatiff, Prag",
                },
                date: "15/1/2004 - 15/2/2004",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "1000 much a 1 noc, Klub Mecca, Praha",
                    en: "1000 FLIES a 1 NIGHT, Club Mecca, Prague",
                    de: "1000 Fliegen und eine Nacht, Klub Mecca, Prag",
                },
                date: "7/7/2003 - 10/9/2003",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },

            {
                title: {
                    cs: "Bublinárium, Club Utopie, Praha",
                    en: "Bublinárium, Club Utopie, Prague",
                    de: "Blasenraum, Klub Utopie, Prag",
                },
                date: "10/1/2002 - 10/2/2002",
                type: 'message',
                content: null // Může být null - zobrazí se jen datum s nadpisem
            },
];
