<p-menubar #mainMenu [model]="items" [styleClass]="'lang-'+selectedLang" (clickOutside)="clickOut($event)">
    <ng-template pTemplate="start">
        <div class="menu-left">
            <a href="/" class="menu-home">
                <img class='menu-logo' src="/assets/img/e-art-logo.png" alt="E-art logo" style="width:3em">
                <div class="menu-label">
                    <div class="l1">Edita Pattová</div>
                    <div class="l2">/{{ (activePage$ | async) || '' | translate }}</div>
                </div>
            </a>
        </div>
    </ng-template>
    <ng-template pTemplate="end">
        <app-language-menu #languageMenu></app-language-menu>
        <form name="search-form" (ngSubmit)="searchFor()" style="display:none">
            <input type="text" pInputText class="menu-search-edit-box p-inputtext-sm"
                [(ngModel)]=searchText name="search-text"
                placeholder="{{ 'menu.search-for' | translate }}"
                style="max-width:9em; margin-right:.25em;"
            >
            <p-button name="search-button" icon="pi pi-search" size="small" type="submit"></p-button>
        </form>
    </ng-template>
</p-menubar>
