import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import { provideRouter, withDebugTracing, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultilangModule } from './trans/multilang.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withDebugTracing(),
      withRouterConfig({paramsInheritanceStrategy: 'always'})),
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        })
    ),
    importProvidersFrom(
        MultilangModule.forRoot()
    )
  ]
};
