<div class="rakosnicek-container">
  <div class="rakosnicek-inner" (contextmenu)="$event.preventDefault()">
    <img class="image-first" src="/assets/img/rakosnicek-bw.png" alt="Rákosníček bw" />
    <img class="image-over" src="/assets/img/rakosnicek-color.png" alt="Rákosníček color"
        style="opacity:0"
        [@rakosnicekOpacity]="state"
        [attr.usemap]="'#rakosnicek-map' + no"
        (mouseover)="rakosnicekHoverImg($event, true)" (mouseout)="rakosnicekHoverImg($event, false)"
        (touchmove)="rakosnicekMove($event)"
        (touchstart)="rakosnicekMove($event)"
        (touchend)="rakosnicekTouchEnd($event)"
        (touchcancel)="rakosnicekTouchEnd($event)"
    />

    <map name="rakosnicek-map{{no}}" id="rakosnicek-map">
        <area class="rakosnicek-area-bg" alt="vedle Rákosníčka" title="vedle Rákosníčka" href="" onclick="return false;"
            style="cursor:default"
            shape="default"
            (mouseover)="rakosnicekHoverImg($event, true)" (mouseout)="rakosnicekHoverImg($event, false)"
            (touchmove)="rakosnicekMove($event)"
            (touchstart)="rakosnicekMove($event)"
            (touchend)="rakosnicekTouchEnd($event)"
            (touchcancel)="rakosnicekTouchEnd($event)"
            />
        <area class="rakosnicek-area-fg" alt="Rákosníček zelený" title="Rákosníček zelený" href="" onclick="return false;" (click)="rakosnicekClick()"
            shape="poly" coords="52.5,196,59,193.5,68,197.5,76.5,231,100,259.5,90.5,239,91,223.5,100.5,202,113,194.5,141,191.5,156.5,197,165,202.5,177,223.5,181.5,219,164.5,176,136.5,139,92,94.5,68,82.5,67.5,76,57,68.5,59,64.5,69.5,72,72.5,67,75,73.5,80.5,71,83.5,83,135.5,132,170.5,179,185,220.5,191.5,219,179.5,128,157.5,67,148.5,63,152,58.5,144.5,45,146.5,33,154,43.5,159,42.5,159.5,61,166,62.5,162.5,68,182.5,130,198.5,223,202,223.5,211.5,149,234.5,48,229,40.5,236.5,37,229.5,30,237,34.5,244.5,19,247.5,20,242.5,35,248,35.5,241.5,42,244.5,49,235,58.5,226.5,90,205.5,221,218.5,207,253.5,140,294,79.5,306.5,49,312.5,53,320.5,48,315.5,55,317.5,61,305.5,67,311,65.5,313,70.5,303.5,71,242.5,162,221,207.5,218,225.5,262.5,175,300,144.5,355,110.5,377.5,100,390.5,100,378,105.5,377,113.5,364,109.5,291,153.5,251.5,189,225.5,223,227,230.5,299,192.5,321,186.5,325.5,181,345,178.5,355,166.5,360,171.5,369,170.5,368.5,174,268,209.5,233,233.5,241,236.5,301,223.5,403,228.5,407.5,223,407,228.5,425.5,236,425,239.5,411,238.5,370,227.5,304,226.5,240.5,240,251,253.5,277,247.5,293.5,256,306,268.5,310.5,284,310.5,302,288,324.5,272,329.5,258,327.5,259,330.5,274,339.5,287.5,341,292.5,320,306,308.5,319.5,327,317.5,347,343,357.5,349.5,366,347.5,379,337.5,383,342.5,396,335.5,404,319,401.5,307.5,394,305.5,405,294,411.5,285,407.5,281.5,383,275.5,383,236,343.5,233.5,346,242,381.5,258,403.5,232,415.5,249,442.5,257,447.5,273.5,415,286,410.5,299.5,419,290,436.5,309,437.5,320,444.5,315,460.5,300,461.5,303.5,474,300,483.5,284,482.5,274.5,468,255.5,492,248.5,492,241.5,484,234.5,485,236,478.5,198,421.5,186.5,426,192.5,480,182,498.5,164,495.5,155,506.5,137.5,508,132.5,500,118,501.5,110.5,494,112.5,488,93,480.5,101,462.5,118,458.5,140,466.5,150,465.5,156.5,454,155.5,439,151,426.5,123.5,424,126.5,418,120,368.5,128,304.5,110,294.5,67,288.5,69.5,285,58,276.5,27,266.5,11.5,248,19,230.5,15.5,224,18.5,210,31.5,208,48.5,221,46.5,207,52.5,197"
            (mouseover)="rakosnicekHoverMap($event, true)" (mouseout)="rakosnicekHoverMap($event, false)"
            (touchmove)="rakosnicekMove($event)"
            (touchstart)="rakosnicekMove($event)"
            (touchend)="rakosnicekTouchEnd($event)"
            (touchcancel)="rakosnicekTouchEnd($event)"
            />
    </map>

    <div class="rakosnicek-message">
      <div class='l2' [@bublinaOpacity]="bublinaState">
        <span class="message-span message-bg"  [innerHTML]=bublinaMessage()></span>
        <span class="message-span message-out" [innerHTML]=bublinaMessage()></span>
        <span class="message-span message-in"  [innerHTML]=bublinaMessage()></span>
        <span class="message-X">
          <p-button class="noselect" icon="pi pi-times" [rounded]="true" severity="success" (onClick)="removeBublina()"></p-button>
        </span>
      </div>
    </div>

  </div>
</div>