
export interface TransPair {
  lang: string;
  text: string;
}

export interface TransSet {
  cs?: string;
  en?: string;
  de?: string;
  km?: string;
}

export type TransUnit = string | TransPair[] | TransSet;

export function transOilOnCanvas(value : string) : TransUnit {
  return {
    en: "oil on canvas " + value,
    de: "Öl auf Leinwand " + value,
    cs: "olej na plátně " + value,
  };
}
