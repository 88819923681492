import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BehaviorSubject, Observable, scan, switchMap, tap } from 'rxjs';
import { ContentApiService } from '../services/content-api.service';
import { ContentPaginator } from '../models/models';

import { ScrollTopModule } from 'primeng/scrolltop';
import { FieldsetModule } from 'primeng/fieldset';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { MessageCard } from '../component/message-card';
import { PhotoCard } from '../component/photo-card';
import { GalleryCard } from '../component/gallery-card';
import { VideoCard } from '../component/video-card';

import { MultilangModule } from '../trans/multilang.module';
import * as Texts from '../models/demo-texty';

@Component({
    selector: 'app-content-page',
    standalone: true,
    imports: [CommonModule, TranslateModule, MultilangModule, InfiniteScrollDirective,
        ScrollTopModule, FieldsetModule, TimelineModule, CardModule, ProgressSpinnerModule,
        MessageCard, PhotoCard, GalleryCard, VideoCard
    ],
    templateUrl: './content-page.component.html',
    styleUrl: './content-page.component.scss',
})

export class ContentPageComponent {
    section: string = "";

    intervals: any[] | undefined;

    private contentApi = inject(ContentApiService);
    public paginator$: Observable<ContentPaginator>;

    public loading$ = new BehaviorSubject(true);
    private page$ = new BehaviorSubject(1);

    constructor(private route: ActivatedRoute) {
        this.paginator$ = new Observable(); // TODO zkontrolovat jestli to neni blbost (lazy init v ngAfterViewInit)
    }

    private loadProducts$(): Observable<ContentPaginator> {
        this.contentApi.selectSection(this.section);
        return this.page$.pipe(
            tap(() => this.loading$.next(true)),
                switchMap((page) => this.contentApi.getProducts$(page, page==1 ? 3 : 3)),
                scan(this.updatePaginator, {items: [], page: 0, hasMorePages: true} as ContentPaginator),
            tap(() => this.loading$.next(false)),
        );
    }
    private updatePaginator(accumulator: ContentPaginator, value: ContentPaginator): ContentPaginator {
        if (value.page === 1) {
            return value;
        }

        accumulator.items.push(...value.items);
        accumulator.page = value.page;
        accumulator.hasMorePages = value.hasMorePages;

        return accumulator;
    }

    public loadMoreProducts(paginator: ContentPaginator) {
        if (!paginator.hasMorePages) {
            return;
        }
        this.page$.next(paginator.page + 1);
    }

    ngOnInit() {
        this.section = this.route.snapshot.data['section'];

        this.intervals = [
            {
                marker: false,
            },
            {
                year: "2023",
            },
            {
                year: "2020",
            },
            {
                year: "2015",
            },
            {
                year: "2010",
            },
            {
                year: "2005",
            },
            {
                marker: false,
            },
        ];
    }
    
    ngAfterViewInit() {
        setTimeout(() => {
            this.paginator$ = this.loadProducts$();
        });
    }

    getIntroText() {
        /* TODO - remove this - only for DEVEL */
        if (this.section=='demo') {
            return Texts.pageDEMOText;
        }
        return '';
    }
}
