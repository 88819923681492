import { WebPost } from '../models/models';
import * as Models from '../models/models';

export const pageItemsInstallation: WebPost[] = [

    // Begin of post
    {
        title: {
            en: 'Electropolis',
            cs: 'Elektropolis',
            de: 'Electropolis',
        },
        date: "2022",
        type: 'video',
        content: {
            text: {
                en: "<i>oil on canvas 135 x 200 cm with an interactive projection</i>" + "<br>"
                + ' Electropolis consists of an oil painting combined with a simple video game that runs also in unattended demo-mode and drives you'
                + ' from the era of industrialization to the present life in a great city towards the yet unknown future.' 
                + "<br>" 
                + 'The canvas depicts a skyline of Berlin where the spectators can play the game and help to save the city from going back to the era of steam engines,' 
                + ' but move forward and build the necessary infrastructure. Projected on the painting is a video game and it is lighting up the representations of the everyday issues our society is facing now.' 
                + "<br>" 
                + 'Combining the two mediums creates an interactive projection for spectators to play the game. Using the phone or tablet, each participant controls a figure, here the representation of an airship,' 
                + ' that builds wind, solar and nuclear power plants, high voltage pylons, lights and other infrastructure that power normal city traffic. If you are a good player then the inhabitants drive cars and planes like now.' 
                + ' However, if you play badly then they can ride just bikes, horses or walk as they did through most of our history.',
                cs: "<i>olejomalba na plátně 135 x 200 cm s interaktivní projekcí</i>" + "<br>"
                + ' Elektropolis se skládá z olejomalby na plátně a jednoduché videohry, která nyní běží v demo-režimu a zavede vás z éry industrializace do současného života ve velkém městě k dosud neznámé budoucnosti.'
                + "<br>"
                + 'Plátno zobrazuje panorama Berlína, kde si diváci mohou zahrát hru a pomoci zachránit město před návratem do éry parních strojů, ale posunout se a vybudovat potřebnou infrastrukturu.' 
                + ' Na obraz je promítána videohra a osvětluje reprezentace každodenních problémů, kterým nyní naše společnost čelí.'
                + "<br>"
                + 'Spojením těchto dvou médií vzniká interaktivní projekce, kterou si diváci mohou zahrát. Každý účastník může pomocí telefonu nebo tabletu ovládat figurku, zde znázornění vzducholodě, která staví větrné, solární a jaderné elektrárny,' 
                + ' stožáry vysokého napětí, světla a další infrastrukturu, která pohání běžný městský provoz. Pokud jste dobrý hráč, pak obyvatelé mohou jezdit auty a letadly jako nyní. Pokud však hrajete špatně, mohou jezdit jen na kole,' 
                + ' na koni nebo chodit, jako to dělali po většinu naší historie.',
                de: "<i>Öl auf Leinwand 135 x 200 cm mit interaktiver Projektion</i>" + "<br>"
                + ' Electropolis besteht aus einem Ölgemälde auf Leinwand und einem einfachen Videospiel, das jetzt im Demomodus läuft und Sie vom Industriezeitalter über das heutige Großstadtleben in eine noch unbekannte Zukunft entführt.'
                + "<br>"
                + 'Der Bildschirm zeigt die Skyline von Berlin, wo die Zuschauer ein Spiel spielen und dabei helfen können, die Stadt vor der Rückkehr ins Dampfmaschinenzeitalter zu bewahren.' 
                + ' Sondern voranzukommen und die notwendige Infrastruktur aufzubauen. Ein Videospiel wird auf das Bild projiziert und beleuchtet eine Darstellung der alltäglichen Probleme, mit denen unsere Gesellschaft jetzt konfrontiert ist.'
                + "<br>"
                + 'Durch die Kombination dieser beiden Medien entsteht eine interaktive Projektion, die das Publikum abspielen kann. Jeder Teilnehmer kann ein Telefon oder Tablet verwenden, um eine Figur zu steuern,' 
                + ' hier eine Darstellung eines Luftschiffs, das Wind-, Solar- und Atomkraftwerke, Hochspannungsmasten, Lichter und andere Infrastruktur baut, die den normalen Stadtverkehr antreibt.' 
                + ' Wenn Sie ein guter Spieler sind, können die Einwohner wie jetzt Autos und Flugzeuge fahren. Wenn Sie jedoch schlecht spielen, können sie einfach Fahrrad fahren, reiten oder laufen, wie sie es für den größten Teil unserer Geschichte getan haben.',
            },
            type: 'vimeo',
            videoId: '772593058',
        },
    },

    {
        title: "Seattle Creatures",
        date: "2020",
        type: 'video',
        content: {
            text: {
                en: "<i>oil on canvas 135 x 200 cm with an interactive projection</i>" + "<br>"
                + 'Seattle Creatures consists of an oil painting and a game inspired by the old-school video game Space invaders.' 
                + "<br>" 
                + 'The canvases depict a skyline of Seattle where the spectators can project their desires and excitement. Projected on the painting is a video game, which I made in cooperation with Pavel Spilka' 
                + ' and it is lighting up the representations of our fears in contemporary life.' 
                + "<br>" 
                + 'Combining the two mediums creates an interactive projection for spectators to play the game. Using a phone or tablet, each participant controls a figure, here the representation of a submarine or a fly,' 
                + ' that shoots the enemies, but watch out the enemies are shooting on you as well! The better the player, the longer he can play with my painting.',
                cs: "<i>olejomalba na plátně 135 x 200 cm s interaktivní projekcí</i>" + "<br>"
                + 'Seattle Creatures se skládá z olejomalby na plátně a počítačové hry inspirované klasickou videohrou Space invadors. '
                + "<br>"
                + 'Plátno zobrazuje panorama Seattlu do něhož mohou diváci promítat svá přání a nadšení. Na malbu je promítána počítačová hra, kterou jsme vytvořili ve spolupráci s Pavlem Spilkou pomocí ní se rozvěcují zpodobnění našich obav v současném světě.'
                + "<br>"
                + 'Kombinace těchto dvou médií vytváří interaktivní projekci na níž diváci hrají hru. Pomocí telefonu nebo tabletu kontroluje každý hráč jednu figuru zde ponorku nebo mouchu, kterou střílí po nepřátelích, ale pozor nepřátelé mohou střílet i na vás!' 
                + ' Čím lepší hráč, tím déle si může hrát s mým obrazem.',
                de: "<i>Öl auf Leinwand 135 x 200 cm mit interaktiver Projektion</i>" + "<br>"
                + 'Seattle Creatures besteht aus einem Ölgemälde und einem Spiel das vom alten Videospiel Space Invaders inspiriert ist. '
                + "<br>"
                + 'Die Leinwände zeigen eine Skyline von Seattle auf der die Zuschauer ihre Wünsche und ihre Aufregung projizieren können. Auf das Gemälde ist ein Videospiel projiziert das ich in Zusammenarbeit mit Pavel Spilka gemacht habe und das die Darstellungen unserer Ängste im zeitgenössischen Leben beleuchtet.'
                + "<br>"
                + 'Durch die Kombination der beiden Medien wird eine interaktive Projektion für die Zuschauer erstellt, um das Spiel zu spielen. Mit einem Telefon oder Tablet steuert der Teilnehmer eine Figur, entweder die Darstellung eines U-Bootes oder einer Fliege, die versucht den Gegner  abzuschiessen.'
                + "<br>"
                + 'Aber achten Sie auch darauf, dass der Gegner auf Sie schiesst! Je besser der Spieler, desto länger das Spiel.',
            },
            type: 'youtube',
            videoId: 'HGYXeAja2c8',
        },
    },

    {
        title: {
            en: 'Tiger in Seattle',
            cs: 'Tygr v Seattlu',
            de: 'Tiger in Seattle',
        },
        date: "2020",
        type: 'video',
        content: {
            text: {
                en: "<i>Oil on windshield of vehicle 150 x 90 cm / video projection</i>",
                cs: "<i>Olejomalba na předním skle osobního vozidla 150 x 90 cm / videoprojekce</i>",
                de: "<i>Öl auf Windschutzscheibe des Fahrzeugs 150 x 90 cm /Projektion</i>",
            },
            type: 'youtube',
            videoId: 'XIJ79IgO-Uw',
        },
    },

    {
        title: {
            en: 'Neon Dreamer',
            cs: 'Neon Dreamer',
            de: 'Neon Dreamer',
        },
        date: "2019",
        type: 'video',
        content: {
            text: {
                en: "<i>9 conjoined oil paintings each 40x50 cm with an interactive projection</i>" + "<br>"
                + 'Neon Dreamer consists of nine oil paintings and a game inspired by the old-school video game Pacman.' 
                + "<br>" 
                + 'The canvases depict a universal American city where the spectators can project their desires and excitement. Projected on the painting is my video game, that jumps between the conjoined canvases,' 
                + ' lighting up the representations of the neon lights in the city.' 
                + "<br>" 
                + 'the two mediums creates an interactive projection for spectators to play the game. Using a phone or tablet, each participant controls a figure, called dreamer, that runs to various bars.' 
                + ' There, the dreamer drinks beer and collects money for more drinks, but watch out for those cops! The better the player, the happier the dreamer will be and the longer you get to play with my paintings.',
                cs: "<i>9 propojených olejomaleb na plátně, každé 40x50 cm s interaktivní projekcí</i>" + "<br>"
                + 'Neon Dreamer se skládá z devíti olejomaleb na plátně a počítačové hry inspirované starou videohrou Pacman. Plátna zobrazují univerzální Americké město do něhož mohou diváci promítat svá přání a nadšení.' 
                + ' Na malbu je promítána počítačová hra, která přebíhá mezi propojenýmy plátny na nichž se rozvěcují malby neonových světel ve městě.'
                + "<br>"
                + 'Kombinace těchto dvou médií vytváří interaktivní projekci na níž diváci hrají hru. Pomocí telefonu nebo tabletu kontroluje každý hráč jednu figuru nazvanou dreamer - snílek, který běhá do různých barů.' 
                + ' Tam snílek pije pivo a sbírá peníze na další drinky, ale pozor na policisty! Čím lepší hráč, tím je snílek spokojenejší a hráč si může déle hrát s mýmy obrazy.',
                de: "<i>Die Eintragung von der interaktiv Projektion an die 9 Bilder auf Leinwand 40 x 50 cm</i>" + "<br>"
                + 'Neon Dreamer besteht aus neun Ölgemälden und einem Spiel das vom Videospiel Pacman der alten Schule inspiriert ist.'
                + "<br>"
                + 'Die Leinwände zeigen eine universelle amerikanische Stad wo die Zuschauer ihre Wünsche und Aufregung projizieren können.'
                + ' Auf dieses Gemälde ist mein Videospiel projiziert das zwischen den verbundenen Leinwänden springt und die Darstellungen der Neonlichter in der dargestellten Stadt beleuchtet.'
                + "<br>"
                + 'Durch die Kombination der beiden Medien wird eine interaktive Projektion für die Zuschauer erstellt, um das Spiel zu spielen.'
                + ' Mit einem Telefon oder Tablet steuert jeder Teilnehmer eine Figur namens Träumer, die zu verschiedenen Balken führt.'
                + "<br>"
                + 'Dort trinkt der Träumer Bier und sammelt Geld für mehr Getränke, umso mehr der Traümer trinkt umso glücklicher wird er und das Spiel verlängert sich, aber passt auf diese Bullen auf.',
            },
            type: 'vimeo',
            videoId: '320510439',
        },
    },

    {
        title: {
            en: 'African Tiger',
            cs: 'Africký Tygr',
            de: 'Africanischer Tiger ',
        },
        date: "2012",
        type: 'video',
        content: {
            text: {
                en: "<i>Oil on windshield of vehicle 140x70 cm /video projection, soundtrack by Ondrej Ďorko</i>" + "<br>"
                + 'It is an absurd situation, so I chose title "African tiger". The painting is painted on a windshield of a vehicle, because in African National Parks it is not possible to get around without a car.' 
                + ' It would not be advisable anyway, if you do not want to be eaten by a felid.'
                + ' The cat, who is running in the picture was hit by car some time ago and this tightened him even more with the theme of replacement.',
                cs: "<i>Olej na předním skle osobního vozidla 140x70 cm /video projekce, zvuk: Ondrej Ďorko</i>" + "<br>"
                + 'Jde o absurdní situaci, proto jsem zvolila název „Africký tygr“. Malba je namalovaná na skle od auta, protože v Afrických Národních Parcích se člověk jinak než autem pohybovat nesmí.' 
                + ' Nebylo by to ani moc rozumné, pokud nechce posloužit jako potrava'
                + ' pro kočkovité šelmy. Kocoura, který běhá po obraze mi před časem srazilo auto, což ho pevněji spojilo s tématem záměny.',
                de: "<i>Öl auf Windschutzscheibe des Fahrzeugs 140x70 cm /Projektion, Musik: Ondrej Ďorko</i>" + "<br>"
                + 'Es geht um absurde Situation, deshalb habe ich den Namen "Afrikanischer Tiger" ausgewählt. Das Bild ist an der Windschutzscheibe aufgemalt, weil man in Afrikanischen Nationalparken nur mit Auto bewegen darf.' 
                + ' Es wäre auch nicht vernünftig, wenn man nicht als Nahrung für katzenartige Raubtiere dienen will. Den Kater, der am Bild läuft, hat vor einer Zeit Auto umgestoßen. Das hat ihn'
                + ' noch fester mit dem Thema der Verwechslung verbunden.',
            },
            type: 'vimeo',
            videoId: '55783932',
        },
    },

    {
        title: {
            en: "Santa and Guitarist II.",
            cs: "Santa a kytarista II.",
            de: "Santa und Gitarrist II.",
        },
        date: "2013",
        type: 'photo',
        content: {
            description: {
                en: "<i>Recording of a projection on 3 oil paintings on canvas 65 x 65 cm, sound: Ondrej Ďorko</i>" + "<br>"
                + "This collection comes to life just in a connection with light coming from a data projector. This makes it also difficult to record a full quality of this installation," 
                + " which you could fully enjoy just “alive” in a gallery. It is a combination of two different media - a physical presence of classical painting with a projected moving image." 
                + "<br>" 
                + "The subject of inspiration for this project were flashing neon figures, which we meet so often in shop windows, especially in a Christmass time." 
                + " That is why the figures in my paintings are placed in front of a glass wall of a bank and their advertisements. Santa and - guitarist have the same job this time: spend, consume and give away..." 
                + " until it is possible. Finally they both end up in briefs and even so: a poster provokes them again: Buy now, pay us next year!",
                cs: "<i>Záznam projekce na 3 olejomalby na plátně 65 x 65 cm, zvuk: Ondrej Ďorko</i>" + "<br>"
                + 'Tento ožívá a nabývá smysl teprve pomocí světla z projektoru. To ho také činí hůře zaznamenatelným zážitkem jehož plnou kvalitu může člověk ocenit teprve „naživo”.' 
                + ' Jde o kombinaci dvou odlišných médií - spojení fyzické přítomnosti klasického malovaného obrazu s pohyblivýmy obrázky promítanýmy.' 
                + "<br>" 
                + 'Námětem mi byly blikacící neonové postavy, které tak často potkáváme ve výkladních skříních obchodů zejména v období Vánoc. Proto jsou postavy na mých obrazech umístěny před skleněnou stěnu bank a jejich reklam.' 
                + ' Santa i kytarista mají tentokrát stejný úkol: utrácet, konzumovat a rozdávat ... dokud to jde. Nakonec skončí oba ve slipech a přesto - reklama je opět nabádá "Nakupujte teď, plaťte až příští rok!"',
                de: "<i>Die Eintragung von der Projektion an die 3 Bilder auf Leinwand 65 x 65 cm, Klang: Ondrej Ďorko</i>" + "<br>"
                + 'Dieses Set bestehend aus 3 Bildern auf Leinwand belebt und nimmt nur Sinn durch das Licht aus dem Projektor.'
                + ' Das macht es auch schwieriger registriertes Erlebnis und die volle Qualität kann man erst “live” schätzen. Es ist eine Kombination'
                + ' von zwei verschiedenen Medien - die physische Präsenz des klassischen Bildes und daran gemalte projizierte bewegliche Bilder.' 
                + "<br>" 
                + 'Beide Figuren zielen auf dasselbe ab: So viel Geld wie nur möglich ausgeben! Beide stehen am Ende bis auf die Unterhosen entkleidet mit nichts mehr da als mit einem letzten Gesöff!' 
                + ' Währenddessen lassen wir uns von der Werbung aber schon das nächste eintrichtern: "Kaufen Sie heute, zahlen Sie erst nächstes Jahr!"'
                + "<br>"
                + 'Die Installation wirkt aus den beiden gegensätzlichen Medien heraus - der klassischen Malerei auf Leinwand und der Lichtprojektion!' 
                + ' Blinkende Lichtfiguren kennen wir als festlichen Fensterschmuck für Weihnachten! In dieser Installation wurden die Figuren bewußt vor die Glasfassaden von Banken und Geschäften gesetzt!', 
            },
            src: '/assets/img/Paintings/Santa_2013/Santa_dva.jpg',
        },
    },

    {
        title: {
            en: 'Ritual',
            cs: 'Rituál',
            de: 'Ritual',
        },
        date: "2008",
        type: 'video',
        content: {
            text: {
                en: "<i>Recording of a projection on an oil painting on canvas 120 x 160 cm</i>" + "<br>"
                + 'This project comes to life just in a connection with light coming from a data projector and sounds, which were recorded in Mexico.' 
                + ' This makes it also difficult to record a full quality of this installation, which you could fully enjoy just “alive” in a gallery.'
                + ' It is a combination of two different media - a physical presence of classical painting with a projected moving image. I was also'
                + ' caught by the opportunity of painting two paintings on one canvas and than with the use of a projection of opposite colours I show always just one of them.' 
                + "<br>" 
                + 'The subject of inspiration for this project was Mayan double immolation ritual. There, during the ceremony was torn off the hard'
                + ' of the sacrified victim and it was used as a dish for the god of Sun, whos deputy was an eagle - an animal coming from heaven.' 
                + ' After that was the body thrown down from the pyramid, so the mother Earth got also her dish and would be fertile … In my work I used this theme in a relieved way,' 
                + ' so here is sacrificed a whale - an animal, which has not much in common with Mayan culture. Also under the pyramide are silhouettes of contemporary people,' 
                + ' who are standing, talking together and dancing, so sometimes it makes the impression of a party … the Maya people as well probably perceived this rite more as a happy event,' 
                + ' where they met each other, talked and thanks to which they ensured the favour of gods.',
                cs: "<i>Záznam videoprojekce na olejomalbu na plátně 120 x 160 cm</i>" + "<br>"
                + 'Projekt ožívá a nabývá smysl teprve pomocí světla z projektoru a zvuků, které byly nahrány v Mexiku. To ho také činí hůře zaznamenatelným zážitkem jehož plnou kvalitu může člověk ocenit teprve „naživo”.' 
                + ' Jde o kombinaci dvou odlišných médií - spojení fyzické přítomnosti klasického malovaného obrazu s pohyblivýmy obrázky promítanýmy. Zaujala mě také možnost namalovat dva obrazy na jedno plátno a' 
                + ' potom pomocí systému protikladných barev odkrýt vždy jen jeden z nich.' 
                + "<br>" 
                + 'Námětem je mayský obětní rituál, při němž bylo během obřadu obětovanému na vrcholu pyramidy vyrváno srdce, které posloužilo jako pokrm pro boha Slunce, jehož poslem byl orel - zvíře snášející se z nebes.' 
                + ' Poté bylo tělo obvykle hozeno z pyramidy dolů, aby se najedla také Matka Země a dávala bohatou úrodu … Ve své práci jsem chtěla toto téma trochu odlehčit, takže zde je obětována velryba - zvíře,' 
                + ' které s Mayi moc nesouvisí. Také pod pyramidou jsou siluety současných lidí, kteří stojí, povídají a tancují, takže to místy působí jako párty … i mayové tento rituál zřejmě vnímali spíše jako radostnou událost,' 
                + ' při níž se setkávali, bavili se a pomocí něhož si zajišťovali přízeň bohů.',
                de: "<i>Die Eintragung von der Projektion an des Bild auf Leinwand 120 x 160 cm</i>" + "<br>"
                + 'Das Projekt belebt und nimmt nur Sinn, durch das Licht des Projektors und Klänge, die in Mexiko aufgenommen wurden.' 
                + ' Das macht es auch schwieriger registriertes Erlebnis und die volle Qualität kann man erst “live” schätzen.' 
                + ' Es ist eine Kombination von zwei verschiedenen Medien - die physische Präsenz des klassischen Bildes und daran gemalte projizierte bewegliche Bilder.' 
                + ' Ich nahm auch die Möglichkeit, zwei Bilder an eine Leinwand zu malen und dann durch ein System von gegensätzlichen Farben immer nur'
                + ' ein Bild von diesen zwei aufzudecken.' 
                + "<br>" 
                + 'Das Thema ist das Maya-Opfer-Ritual. Während der Zeremonie wurde das Herz des Opfers auf der Spitze der Pyramide abgeringt.'
                + ' Das diente als Nahrung für den Sonnengott. Sein Bote war Adler - das fliegende Tier vom Himmel. Danach war der Körper in der Regel von der Pyramide geworfen,' 
                + ' um sich die Mutter der Erde anzuessen und eine reiche Ernte zu geben. In meiner Arbeit wollte ich'
                + ' das Thema ein wenig zu erleichtern, so dass hier ein Wal geopfert wird - ein Tier, das Maya nicht zu Bezug hat.' 
                + ' Auch unter der Pyramide sind Silhouetten der jetzigen Menschen, die stehen, plaudern und tanzen. Stellenweise wirkt das wie eine Party.' 
                + ' Auch Maya haben wahrscheinlich dieses Ritual wie ein freudiges Ereignis wahrgenommen, wo sie sich trafen und sich unterhielten. Dadurch besorgten sie sich die Gunst der Götter.',
            },
            type: 'vimeo',
            videoId: '31327468',
        },
    },

    {
        title: {
            en: "Santa and Guitarist I.",
            cs: "Santa a kytarista I.",
            de: "Santa und Gitarrist I.",
        },
        
        date: "2008",
        type: 'photo',
        content: {
            description: {
                en: "<i>Recording of a projection on 3 oil paintings on canvas 65 x 65 cm</i>" + "<br>"
                + "This collection comes to life just in a connection with light coming from a data projector. This makes it also difficult to record a full quality of this installation," 
                + " which you could fully enjoy just “alive” in a gallery. It is a combination of two different media - a physical presence of classical painting with a projected moving image." 
                + "<br>"
                + "The subject of inspiration for this project were flashing neon figures, which we meet so often in shop windows, especially"
                + " in a Christmas time. That's why the figures in my paintings are placed in front of a shopping center glass wall. One of the figures"
                + " is Santa Claus - popular advertising symbol of consumer society, which is reminding us: “Have you bought presents for all"
                + " your relatives already? If not, than move quickly.” Guitarist is the complement: “Enjoy your holiday, the time of wild parties!” Also"
                + " their figures are “alive” and “move” thanks to the principle of complementary colours. ",
                cs: "<i>Záznam projekce na 3 olejomalby na plátně 65 x 65 cm</i>" + "<br>"
                + 'Tento ožívá a nabývá smysl teprve pomocí světla z projektoru. To ho také činí hůře zaznamenatelným zážitkem jehož plnou kvalitu může člověk ocenit teprve „naživo”.' 
                + ' Jde o kombinaci dvou odlišných médií - spojení fyzické přítomnosti klasického malovaného obrazu s pohyblivýmy obrázky promítanýmy.' 
                + "<br>"
                + 'Námětem mi byly blikacící neonové postavy, které tak často potkáváme ve výkladních skříních obchodů zejména v období Vánoc.' 
                + ' Proto jsou postavy na mých obrazech umístěny před skleněnou stěnu nákupního centra. Jednou z postav je Santa Klaus - oblíbený' 
                + ' reklamní symbol konzumní společnosti, který nám připomíná: „Koupili jste již všem příbuzným dárek? Jestli ne, tak honem do toho.”' 
                + ' Kytarista ho doplňuje: „Užívejte si svátky, období pracovního klidu a bujarých večírků!” Také jejich postavy „ožívají” a „pohybují se” pomocí systému protikladných barev. ',
                de: "<i>Die Eintragung von der Projektion an die 3 Bilder auf Leinwand 65 x 65 cm</i>" + "<br>"
                + 'Dieses Set bestehend aus 3 Bildern auf Leinwand belebt und nimmt nur Sinn durch das Licht aus dem Projektor.'
                + ' Das macht es auch schwieriger registriertes Erlebnis und die volle Qualität kann man erst “live” schätzen. Es ist eine Kombination'
                + ' von zwei verschiedenen Medien - die physische Präsenz des klassischen Bildes und daran gemalte projizierte bewegliche Bilder.' 
                + "<br>"
                + 'Das Thema waren blinkende Neon-Figuren, die wir so oft in den Schaufenstern der Geschäfte sehen, vor allem zu Weihnachten.' 
                + ' Daher sind die Figuren vor der Glaswand des Einkaufszentrums platziert. Eine Figur ist Santa Claus - ein beliebtes Werbe-Symbol'
                + ' der Konsumgesellschaft, die uns daran erinnert: “Haben Sie schon allen Verwandten ein Geschenk gekauft? Wenn nicht, tun Sie es schnell.”' 
                + ' Gitarrist ergänzt ihn: “Genießen Sie die Feiertage und ausgelassene Partys!” Ihre Figuren “beleben” und “bewegen sich” durch ein System von gegensätzlichen Farben. ', 
            },            
            src: '/assets/img/Paintings/Santa_2012/Santa_prvni.jpg',
        },
    },

    {
        title: {
            en: 'House without flaw',
            cs: 'Dům bez kazu',
            de: 'Fehlerlos Haus',
        },
        date: "2006",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>" + "<br>"
                + 'I created this video in an old house right before demolition in the center of Prague during the theater festival /"4 days in movement".',
                cs: "<i>video</i>" + "<br>"
                + 'Vytvořila jsem toto video ve starém domě během konání festivalu /"4 dny v pohybu."',
                de: "<i>Video</i>" + "<br>"
                + 'Dieses Video habe ich in einem alten Haus kurz vor dem Abriss im Zentrum von Prag während des Theaterfestivals /„4 Tage in Bewegung“ erstellt.',
            },
            type: 'vimeo',
            videoId: '45498557',
        },
    },

    {
        title: {
            en: 'Mezipatra I.',
            cs: 'Mezipatra I.',
            de: 'Mezipatra I.',
        },
        date: "2004",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>" + "<br>"
                + 'I created this video to project it at the gay festival called /"Mezipatra" in Prague.',
                cs: "<i>video</i>" + "<br>"
                + 'Video jsem vytvořila a promítala na gay festivalu /"Mezipatra" v Praze.',
                de: "<i>Video</i>" + "<br>"
                + 'Ich habe dieses Video erstellt, um es auf dem Schwulenfestival /„Mezipatra“ in Prag zu zeigen.',
            },
            type: 'vimeo',
            videoId: '85076321',
        },
    },

    {
        title: {
            en: 'Mezipatra III.',
            cs: 'Mezipatra III.',
            de: 'Mezipatra III.',
        },
        date: "2004",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>" + "<br>"
                + 'I created this video to project it at the gay festival called /"Mezipatra" in Prague.',
                cs: "<i>video</i>" + "<br>"
                + 'Video jsem vytvořila a promítala na gay festivalu /"Mezipatra" v Praze.',
                de: "<i>Video</i>" + "<br>"
                + 'Ich habe dieses Video erstellt, um es auf dem Schwulenfestival /„Mezipatra“ in Prag zu zeigen.',
            },
            type: 'vimeo',
            videoId: '85076322',
        },
    },

    {
        title: {
            en: 'Cinderella',
            cs: 'Sněhurka',
            de: 'Schneewittchen',
        },
        date: "2006",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>",

                cs: "<i>video</i>",
                
                de: "<i>Video</i>",
                
            },
            type: 'vimeo',
            videoId: '45502261',
        },
    },

    {
        title: {
            en: 'Fly',
            cs: 'Smrti mouchy',
            de: '1000 Fliegen',
        },
        date: "2003",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>" + "<br>"
                + 'This video shows various possibilities how a fly could die.',
                cs: "<i>video</i>" + "<br>"
                + 'Toto video je o různých možnostech smrti mouchy.',
                de: "<i>Video</i>" + "<br>"
                + 'Dieses Video zeigt verschiedene Möglichkeiten, wie eine Fliege sterben könnte.',
            },
            type: 'vimeo',
            videoId: '31328653',
        },
    },
    
    {
        title: {
            en: 'Bears',
            cs: 'Medvědi',
            de: 'Bären',
        },
        date: "2004",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>",

                cs: "<i>video</i>",
                
                de: "<i>Video</i>",
                
            },
            type: 'vimeo',
            videoId: '85076323',
        },
    },

    {
        title: {
            en: 'Hydrant',
            cs: 'Hydrant',
            de: 'Hydrant',
        },
        date: "2004",
        type: 'video',
        content: {
            text: {
                en: "<i>video</i>",

                cs: "<i>video</i>",
                
                de: "<i>Video</i>",
                
            },
            type: 'vimeo',
            videoId: '86597388',
        },
    },
    // End of Post

];
