<div class="gallery-outer">

  <post-text [text]="data.text" [expanded]="data.expanded"></post-text>

  <div class="gallery-wrapper" [style]="getGalleryStyle()" (click)="$event.stopPropagation()">
    <p-galleria
      #galleria
      [value]=data.items
      [(activeIndex)]="activeIndex"
      [numVisible]="5"
      [showIndicators]=showIndicators
      [showThumbnails]=showThumbnails
      [showItemNavigators]="true"
      [showItemNavigatorsOnHover]="true"
      [responsiveOptions]=""
      [circular]="true"
      [autoPlay]="true"
      [transitionInterval]="3000"
      containerClass="gallery-container{{this.fullscreen ? ' fullscreen' : ''}}{{this.smallIndicators ? ' small-indi' : ''}}"
  >
      <ng-template pTemplate="item" let-item>
        <img class="main-img" [src]="item.thumb" alt="{{ data.items[activeIndex]!.title | multilang }}" (click)="toggleFullScreen()" [class]="{ 'fullscreen': fullscreen }" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
          <img [src]="item.thumb" alt="{{item.title | multilang}}" />
        </div>
      </ng-template>
      <ng-template pTemplate="footer" *ngIf="showTitle" let-item>
        <div class="gallery-footer">
          <button type="button" pButton icon="pi pi-list" (click)="onThumbnailButtonClick()" class="thumbnail-button"></button>
          <div *ngIf="data.items" class="title-container">
            <div class="gallery-footer-no">{{ activeIndex + 1 }}/{{ data.items.length }}</div>
            <div>
              <div class="gallery-footer-title">{{ data.items[activeIndex].title | multilang }}</div>
              <div class="gallery-footer-description">{{ data.items[activeIndex].description | multilang }}</div>
            </div>
          </div>
          <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()" class="fullscreen-button"></button>
        </div>
      </ng-template>
    </p-galleria>
  </div>
</div>