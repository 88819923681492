import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { TransUnit, TransPair, TransSet } from './multilang.types';

@Injectable({
  providedIn: 'root'
})

export class MultilangService {
  currentLang: string = '';

  constructor(private ts: TranslateService, @Optional() @SkipSelf() multilangService?: MultilangService) {
    if (multilangService) {
      throw new Error('MultilangService is already loaded');
    }
    // TODO: lepsi bude: zkusit LangChangeEvent
    this.setLang(ts.currentLang);
  }

  isTransSet(value: any): boolean {
    if ('cs' in value) return true;
    if ('en' in value) return true;
    if ('de' in value) return true;
    if ('km' in value) return true;
    return false;
  }

  outputTransSet(ts: TransSet): string {
    if (this.currentLang == 'en' && ts.en) return ts.en;
    if (this.currentLang == 'cs' && ts.cs) return ts.cs;
    if (this.currentLang == 'de' && ts.de) return ts.de;
    if (this.currentLang == 'km' && ts.km) return ts.km;
    // console.log("TransSet.toLang(lang='" + this.currentLang + "' not exists");
    // Translation for current lang is missing
    // Trying translations by priority
    if (ts.en) return ts.en;
    if (ts.cs) return ts.cs;
    if (ts.de) return ts.de;
    if (ts.km) return ts.km;
    return '';
  }

  outputTransPair(tp: TransPair[]) {
    if (tp.length > 0) {
      const found = tp.find(pair => pair.lang == this.currentLang);
      if (found)
        return found.text;
      else
        return tp[0].text;
    }
    else {
      // TODO: change to console.log
      throw new Error('MultilangService: TransPair[] empty');
    }
  }

  getTrans(value: TransUnit) {
    if (typeof value === 'string') {
      return value;
    }
    if (this.isTransSet(value)) {
      return this.outputTransSet(value as TransSet);
    }
    if (Array.isArray(value)) {
      return this.outputTransPair(value as TransPair[]);
    }
    // TODO: change to console.log
    throw new Error('MultilangService: TransUnit value unexpected');
  }

  setLang(value: string) {
    this.currentLang = value;
  }
}
