import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';

import { MultilangModule } from '../trans/multilang.module';

import { PostText } from "./post-text";
import { Galleria, GalleriaModule } from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';
import { WebGalleryCard, 
    GALLERY_INDICATORS, GALLERY_PREVIEW, GALLERY_TITLE, GALLERY_BOXES_2, GALLERY_BOXES_3, GALLERY_SMALL_INDI,
    POST_EXPANDED, AUTO_ASPECT
} from '../models/models';

@Component({
    selector: 'gallery-card',
    standalone: true,
    imports: [MultilangModule, PostText, GalleriaModule, ButtonModule],
    templateUrl: './gallery-card.html',
    styleUrl: './gallery-card.scss',
})
export class GalleryCard implements OnInit, OnDestroy {
    data: WebGalleryCard = {
        text: "",
        style: null,
        items : []
    }
    static regexpImgUrl = new RegExp('^/assets/img/');
    @Input() set setData(value: WebGalleryCard) {
        this.data = value;

        this.data.items.forEach((item) => {
            if (!item.thumb) {
                item.thumb = item.src.replace(GalleryCard.regexpImgUrl, "/assets/thumb/");
            }
        });

        this.showIndicators  = (value.style! & GALLERY_INDICATORS) != 0;
        this.showThumbnails  = (value.style! & GALLERY_PREVIEW   ) != 0;
        this.showTitle       = (value.style! & GALLERY_TITLE     ) != 0;
        this.smallIndicators = (value.style! & GALLERY_SMALL_INDI) != 0;
        this.data.expanded   = (value.style! & POST_EXPANDED     ) != 0;
        this.data.autoAspect = (value.style! & AUTO_ASPECT       ) != 0;
    }
    activeIndex: number = 0;
    showIndicators: boolean = false;
    smallIndicators: boolean = false;
    showThumbnails: boolean = false;
    showTitle: boolean = false;
    fullscreen: boolean = false;
    onFullScreenListener: any;

    constructor(
        private cd: ChangeDetectorRef
     ) {}
    
    @ViewChild('galleria') galleria: Galleria | undefined;

    responsiveOptions: any[] = [
        {
            breakpoint: '980px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    ngOnInit() {
        this.bindDocumentListeners();
    }

    onThumbnailButtonClick() {
        this.showThumbnails = !this.showThumbnails;
    }

    toggleFullScreen() {
        if (this.fullscreen) {
            this.closePreviewFullScreen();
        } else {
            this.openPreviewFullScreen();
        }

        this.cd.detach();
    }

    openPreviewFullScreen() {
        let elem = this.galleria?.element.nativeElement.querySelector('.p-galleria');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem['mozRequestFullScreen']) {
            /* Firefox */
            elem['mozRequestFullScreen']();
        } else if (elem['webkitRequestFullscreen']) {
            /* Chrome, Safari & Opera */
            elem['webkitRequestFullscreen']();
        } else if (elem['msRequestFullscreen']) {
            /* IE/Edge */
            elem['msRequestFullscreen']();
        }
    }

    onFullScreenChange() {
        this.fullscreen = !this.fullscreen;
        this.cd.detectChanges();
        this.cd.reattach();
    }

    closePreviewFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    bindDocumentListeners() {
        this.onFullScreenListener = this.onFullScreenChange.bind(this);
        document.addEventListener('fullscreenchange', this.onFullScreenListener);
        document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
        document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
        document.addEventListener('msfullscreenchange', this.onFullScreenListener);
    }

    unbindDocumentListeners() {
        document.removeEventListener('fullscreenchange', this.onFullScreenListener);
        document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
        document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
        document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
        this.onFullScreenListener = null;
    }

    ngOnDestroy() {
        this.unbindDocumentListeners();
    }

    getGalleryStyle() {
        let result = '';
        if (this.data.autoAspect == undefined || !this.data.autoAspect) {
            if (this.data.aspectRatio) {
                result += `aspect-ratio: ${this.data.aspectRatio};`;
            } else {
                result += `aspect-ratio: inherit;`;
            }
        }
        return result;
    }

    fullScreenIcon() {
        return `pi ${this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'}`;
    }
}