import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { DividerModule } from 'primeng/divider';

@Component({
    selector: 'font-samples',
    standalone: true,
    imports: [FieldsetModule, CardModule, DividerModule],
    templateUrl: './font-samples.component.html',
    styleUrl: './font-samples.component.scss',
})
export class FontSamplesComponent {
    text0() {
        return "E-Art • Edita Pattová"
        + " • " + this.translate.instant('menu.photo')
        + " • " + this.translate.instant('menu.painting')
        + " • " + this.translate.instant('menu.exhibition')
        ;
    }
    text() {
        return "E-Art • Edita Pattová"
        + " • " + this.translate.instant('menu.home')
        + " • " + this.translate.instant('menu.photo')
        + " • " + this.translate.instant('menu.painting')
        + " • " + this.translate.instant('menu.installation')
        + " • " + this.translate.instant('menu.exhibition')
        + " • " + this.translate.instant('menu.bio')
        + " • " + this.translate.instant('menu.contact')
        + " <br> tučňáččí skrýší za mříží hlemýždi se plíží • <b>Vítězslav Šťovíček je vůl</b> • ĚŠČŘŽÝÁÍÉÓŮÚŤĎŇ ěščřžýáíéóůúťďň"
        ;
    }
    constructor(private translate: TranslateService) {}
}
