import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';            // <-- required
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {MultilangService} from '../trans/multilang.service';

@Component({
    selector: 'app-language-menu',
    standalone: true,
    imports: [DropdownModule, FormsModule, CommonModule],
    templateUrl: './language-menu.html',
    styleUrl: './language-menu.scss',
})
export class LanguageDropdownComponent {
    countries: any[] | undefined;
    selectedCountry: {id: string; name: string, code: string} | undefined;

    constructor(private translateService: TranslateService, private multilang: MultilangService) {}

    setCountries(values: any[]) {
        this.countries = values;
    }
    onLangChange() {
        if (this.selectedCountry) {
            this.translateService.use(this.selectedCountry.id);
            this.multilang.setLang(this.selectedCountry.id);
            localStorage.setItem('language', this.selectedCountry.id);
        }
    }
    setLang(value: string) {
        let filteredLangs = this.countries?.filter((lang) => {
            return lang.id === value;
        });
        if (filteredLangs && filteredLangs.length > 0) {
            this.selectedCountry = filteredLangs[0];
        }
    }
}
