import { Component, Input, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { TransUnit } from '../trans/multilang.types';
import { MultilangModule } from '../trans/multilang.module';
import { MultilangPipe } from '../trans/multilang.pipe';

@Component({
    selector: 'post-text',
    standalone: true,
    imports: [ CommonModule, MultilangModule ],
    templateUrl: './post-text.html',
    styleUrl: './post-text.scss',
})

export class PostText implements OnDestroy {
    observer: ResizeObserver | undefined;
    textElement: any;
    textInsideElement: any;
    textOutsideElement: any;
    expanderElement: any;
    lineHeight = 0;
    textHeight = 0;
    textOutHeight = 0;
    textLowHeight: boolean = false;
    constructor(private el: ElementRef, private zone: NgZone, private multilangPipe: MultilangPipe) {
    }
    ngAfterViewInit() {
        this.observer = new ResizeObserver((entries) => {
            this.zone.run(() => {
                entries.forEach(entry => {
                    if (entry.target == this.expanderElement) {
                        if (this.lineHeight != (this.expanderElement as HTMLElement).clientHeight)
                            this.lineHeight = (this.expanderElement as HTMLElement).clientHeight;
                    }
                    if (entry.target == this.textOutsideElement) {
                        if (this.textOutHeight != entry.contentRect.height)
                            this.textOutHeight = entry.contentRect.height;
                    }
                    if (entry.target == this.textInsideElement) {
                        if (this.textHeight != entry.contentRect.height)
                            this.textHeight = entry.contentRect.height;
                    }
                });
                if (this.textHeight && this.textOutHeight && this.textHeight > 0 && this.textOutHeight > 0) {
                    this.textLowHeight = (this.textOutHeight > this.textHeight);
                    /*
                    if (this.lineHeight && this.lineHeight > 0) {
                        const lineHeight = (this.lineHeight > 10) ? this.lineHeight : 20;
                        let lines = this.textHeight / lineHeight;
                        console.log('calculated lines=' + lines);
                        const elWidth = this.el.nativeElement.querySelector('.width');
                        if (elWidth)
                            elWidth.innerHTML = 'text height='+this.textHeight+',lines='+lines;
                    }
                    */
                }
            });
        });
        this.textElement = this.el.nativeElement.querySelector('.text');
        this.textOutsideElement = this.el.nativeElement.querySelector('.text-outside');
        this.textInsideElement = this.el.nativeElement.querySelector('.text-inside');
        this.expanderElement = this.el.nativeElement.querySelector('.expander-line');
        if (this.textElement && this.textOutsideElement && this.textInsideElement) {
            this.observer.observe(this.textOutsideElement);
            this.observer.observe(this.textInsideElement);
            if (this.expanderElement) {
                this.observer.observe(this.expanderElement);
            }
        }
    }
    ngOnDestroy() {
        if (this.textOutsideElement)
            this.observer?.unobserve(this.textOutsideElement);
        if (this.textInsideElement)
            this.observer?.unobserve(this.textInsideElement);
        if (this.expanderElement)
            this.observer?.unobserve(this.expanderElement);
    }
    data: {
        text?: TransUnit | null,
        expanded?: boolean | null,
    } = {};
    collapsed : boolean = true;
    @Input() set text(text: TransUnit | undefined) {
        if (text) {
            this.data.text = text;
        }
    }
    @Input() set expanded(value: boolean | undefined) {
        this.data.expanded = value;
    }
    toggle(expand: boolean = false) {
        if (expand || this.collapsed) {
            this.collapsed = !this.collapsed;
        }
        console.log("post-text toggle("+(expand?"true":"false")+") clicked");
    }
    alwaysExpanded() {
        if (this.data.expanded || this.textLowHeight)
            return true;
        return false;
    }
    getTextClass() {
        if (this.alwaysExpanded()) {
            return '';
        }
        if (this.collapsed) {
            return "card-text-collapsible card-text-collapsed";
        }
        return 'card-text-collapsible card-text-expanded';
    }
    getTextStyle() {
        let result = '';
        if (this.textHeight && this.textHeight > 0) {
            let height = this.textHeight + (this.alwaysExpanded() ? 0 : this.collapsed ? 16 : 12);
            result = "height: " + height +'px;';
        }
        return result;
   }
}