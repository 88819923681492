import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContentPaginator, WebPost, WebGalleryCard, GALLERY_SMALL_INDI } from '../models/models';

import { pageItemsPhoto        } from '../models/page-photo';
import { pageItemsPainting     } from '../models/page-painting';
import { pageItemsInstallation } from '../models/page-installation';
import { pageItemsExhibition   } from '../models/page-exhibition';
import * as Texts from '../models/demo-texty';


@Injectable({
    providedIn: 'root'
})
export class ContentApiService {

    items : WebPost[] = [];

    private processDummyResponse(page: number, itemsPerPage: number) : ContentPaginator {
        const items: WebPost[] = [];
        let index: number = (page - 1) * itemsPerPage;
        while (items.length < itemsPerPage && index < this.items.length) {
            const post = this.items[index] as WebPost;

            if (post.type == 'gallery') {
                const gal = post.content as WebGalleryCard;
                if (gal) {
                    if (gal.items.length>30 || gal.text == 'pohadka!') {
                    // moc dlouha galerie - zmenit na photo
                        post.type = 'photo';
                        post.content = {
                            title: 'Fotka',
                            description: "<p><b>Příliš dlouhá galerka - tak si raději povíme pohádku</b>, abysme nerozbili layout</p>" + Texts.textPohadka,
                            src: 'https://www.infracz.cz/images/thumbs/0026711_pohadka-z-kufriku-o-neposlusnych-kuzlatkach-kuzlatka_01_600.jpeg',
                        }
                    }
                    else if (gal.items.length>13) {
                        gal.style = (gal.style ? gal.style : 0) | GALLERY_SMALL_INDI;
                    }
                }
            }
    
            items.push(post);
            index++;
        }
        let container = {
            // items: (page % 3 < 2) ? [...this.items] : [...this.items],
            items: items,
            page: page,
            hasMorePages: index < this.items.length
            /* response.skip + response.limit < response.total */
        };

        return container as ContentPaginator;
    }

    selectSection(section: string) {
        switch(section) {
            case 'photo'       : this.items = pageItemsPhoto; break;
            case 'painting'    : this.items = pageItemsPainting; break;
            case 'installation': this.items = pageItemsInstallation; break;
            case 'exhibition'  : this.items = pageItemsExhibition; break;
            /* TODO - remove this - only for DEVEL */
            case 'demo'        : this.items = Texts.pageItemsDEMO; break;
            default: this.items = [];
        }
    }

    public getProducts$(page: number = 1, itemsPerPage: number = 5): Observable<ContentPaginator> {
        let observable = new Observable<ContentPaginator>(observer => {
            observer.next(this.processDummyResponse(page, itemsPerPage));
        });
        return observable;
    }
}
