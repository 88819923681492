import { TransUnit } from '../trans/multilang.types';

export interface WebPhoto {
  title?: TransUnit;
  description?: TransUnit;
  expanded?: boolean;
  src: string;
  thumb?: string,
}

export interface WebVideoCard {
  text?: TransUnit;
  expanded?: boolean;
  type: 'youtube' | 'vimeo' | 'facebook';
  videoId: string; // Youtube or Vimeo video code (eg. B7Ay_J6iKXM or 269166673)
}

export const GALLERY_INDICATORS: number =   1;
export const GALLERY_PREVIEW   : number =   2;
export const GALLERY_TITLE     : number =   4;
export const GALLERY_BOXES_2   : number =   8;
export const GALLERY_BOXES_3   : number =  16;
export const GALLERY_SMALL_INDI: number =  32;
export const POST_EXPANDED     : number =  64;
export const AUTO_ASPECT       : number = 128;

export interface WebGalleryCard {
  text: TransUnit;
  expanded?: boolean;
  style: number | null;
  aspectRatio?: number | string;
  autoAspect?: boolean;
  items: WebPhoto[];
}

export interface WebMessageCard {
  message: TransUnit;
  expanded?: boolean;
}

export interface WebPost {
  title: TransUnit;
  date: string;
  type: 'message' | 'photo' | 'gallery' | 'video';
  content: WebGalleryCard | WebVideoCard | WebPhoto | WebMessageCard | null;
}

export interface ContentPaginator {
  items: WebPost[];
  page: number;
  hasMorePages: boolean;
}

export function htmlLink(url: string, title? : string | null) : string {
  return "<a href=\"" + url + "\">" + (title ? title : url) + "</a>";

}
