import { ModuleWithProviders, NgModule } from '@angular/core';
import { MultilangService as MultilangService } from './multilang.service';
import { MultilangPipe as MultilangPipe } from './multilang.pipe';

@NgModule({
    declarations: [
        MultilangPipe,
    ],
    exports: [
        MultilangPipe,
    ]
})

export class MultilangModule {
    private static isInitialized: boolean = false;

    constructor() {
      if (!MultilangModule.isInitialized) {
          throw new Error('call forRoot first');
      }
    }
  
    public static forRoot(): ModuleWithProviders<MultilangModule> {
        if (this.isInitialized) {
            throw new Error('do not call forRoot multiple times');
        }
        this.isInitialized = true;

        return {
            ngModule: MultilangModule,
            providers: [
                MultilangService,
                MultilangPipe,
            ],
        };
    }
}
