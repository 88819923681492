import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { TransUnit } from '../trans/multilang.types';
import { PostText } from "./post-text";
import { WebMessageCard } from '../models/models';

@Component({
    selector: 'message-card',
    standalone: true,
    imports: [ CommonModule, PostText ],
    templateUrl: './message-card.html',
    styleUrl: './message-card.scss',
})
export class MessageCard {
    text?: TransUnit;
    expanded?: boolean = false;
    @Input() set setData(value: WebMessageCard | null) {
        if (value?.message) {
            this.text = value.message;
        }
        if (value?.expanded) {
            this.expanded = value.expanded;
        }
    }
}