import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[clickOutside]',
})
export class ClickOutsideSubmenuDirective {

    @Output() clickOutside = new EventEmitter<Event>();

    constructor() {}

    @HostListener('document:click', ['$event'])
    public onClick(event : Event) {
        this.clickOutside.emit(event);
    }
}
