import { WebPost } from './models';
import * as Models from './models';

/* TODO - remove this - only for DEVEL */
export const loremIpsum: string =
      "🦉🦉🦉 <b>Lorem i psům</b> 🐕 <br>"
      +"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit... "
      +"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."
      +"<i>– „de Finibus Bonorum et Malorum“, Cicero, 45 BC</i>";

/* TODO - remove this - only for DEVEL */
export const pageDEMOText: string = "<div class='demoblok' style='display:none'>"
    + "      <div><b>Galerie</b> nad galerkou bude průvodní text"
    + "</div><div><b></b>        GALLERY_INDICATORS – slideshow s puntíkama"
    + "</div><div><b></b>        GALLERY_PREVIEW – tlačítko pro náhledy"
    + "</div><div><b></b>        GALLERY_TITLE – dole pruh s názvem/popis"
    + "</div><div>"
    + "</div><div><b>Fotka</b>         Jen fotka a nad ní text"
    + "</div><div>"
    + "</div><div><b>Youtube video</b> videoId = id youtube videa (je vidět v adrese, např: 1AldOVnZ9F4)"
    + "</div><div><b>Vimeo video</b>   videoId = id vimeo videa (např. 269166673)"
    + "</div><div><b>Facebook video</b> videoId = id facebook videa"
    + "</div></div>"
;

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

export const pageItemsDEMO: WebPost[] = [
    {
        title: {
            cs: "Šťastný nový rok",
            en: "Happy New Year",
            de: "Frohes Neues Jahr",
        },
        date: "2024/1/1",
        type: 'message',
        content: null // Může být null - zobrazí se jen datum s nadpisem
    },

    {
        title: {
            cs: "Veselé vánoce",
            en: "Merry Christmas",
            de: "Frohe Weihnachten",
        },
        date: "2023/12/24",
        type: 'message',
        content: {
            message: {
                cs: '<i>„Nebojte se, hle, zvěstuji vám velikou radost, která bude pro všechen lid. Dnes se vám narodil Spasitel, Kristus Pán, v městě Davidově.“</i> (Lk, 2:10)',
                de: '<i>„Und der Engel sprach zu ihnen: Fürchtet euch nicht! Siehe, ich verkündige euch große Freude, die allem Volk widerfahren wird; denn euch ist heute der Heiland geboren, welcher ist Christus, der Herr, in der Stadt Davids..“</i> (Lukas, 2:10)',
                en: '<i>„Every good and perfect gift is from above, coming down from the Father of the heavenly lights, who does not change like shifting shadows.“</i> - James 1:17',
            }
        }
    },

    {
        title: "Vimeo - Fahren mit 70 Jahre alten VW Käfer",
        date: "2018/5/5",
        type: 'video',
        content: {
            text: {
                de: "Der VW Käfer (VW Typ 1) ist ein Pkw-Modell der unteren Mittelklasse der Marke Volkswagen mit luftgekühltem Vierzylinder-Boxermotor und Heckantrieb, das von Ende 1938 bis Sommer 2003 gebaut wurde."
                    + "<br>Mit über 21,5 Millionen Fahrzeugen war der Käfer das meistverkaufte Automobil der Welt, bevor er im Juni 2002 vom VW Golf übertroffen wurde.",
                cs: "VW Brouk (VW Type 1) je model osobního vozu nižší střední třídy od značky Volkswagen se vzduchem chlazeným čtyřválcovým motorem boxer a pohonem zadních kol vyráběný od konce roku 1938 do léta 2003."
                    + "<br>S více než 21,5 miliony prodaných vozů byl Brouk nejprodávanějším automobilem na světě, než byl v červnu 2002 překonán VW Golfem.",
                en: "The VW Beetle (VW Type 1) is a lower middle class passenger car model from the Volkswagen brand with an air-cooled four-cylinder boxer engine and rear-wheel drive that was built from the end of 1938 to the summer of 2003."
                + "<br>With over 21.5 million vehicles sold, the Beetle was the best-selling automobile in the world before being surpassed by the VW Golf in June 2002.",
                km: "VW Beetle (VW Type 1) គឺជាម៉ូដែលរថយន្តដឹកអ្នកដំណើរថ្នាក់កណ្តាលកម្រិតទាបពីម៉ាក Volkswagen ជាមួយនឹងម៉ាស៊ីនប្រអប់លេខបួនស៊ីឡាំងដែលត្រជាក់ដោយម៉ាស៊ីន និងកង់ខាងក្រោយដែលត្រូវបានបង្កើតឡើងពីចុងឆ្នាំ 1938 ដល់រដូវក្តៅឆ្នាំ 2003 ។"
                + "<br>ជាមួយនឹងការលក់រថយន្តជាង 21.5 លានគ្រឿង Beetle គឺជារថយន្តដែលលក់ដាច់បំផុតនៅលើពិភពលោក មុនពេលត្រូវបានវ៉ាដាច់ដោយ VW Golf ក្នុងខែមិថុនា ឆ្នាំ 2002 ។",
            },
            type: 'vimeo',
            videoId: '269166673',
        },
    },

    {
        title: "Youtube video",
        date: "1988/7/7",
        type: 'video',
        content: {
            text: {
                en: "<b>The tram</b> in Dresden in the 1970s"
                + "<br>Until 1990, a single ticket for a tram or bus ride across Dresden didn't even cost 2 groschen 20 pfennigs (2 groschen)."
                + "<br>In 1972, the \"100 Years of Trams in Dresden\" anniversary was the reason to make an 8 mm film about tram operations at the time. Get in and experience historical impressions that date back 50 years.",
                cs: "<b>Tramvaj</b> v Drážďanech v 70. letech"
                + "<br>Až do roku 1990 nestála jednotná jízdenka na tramvaj nebo autobus přes Drážďany ani 2 groše 20 fenigů (2 groše)."
                + "<br>V roce 1972 bylo výročí „100 let tramvají v Drážďanech“ důvodem k natočení 8mm filmu o tehdejším provozu tramvají. Vstupte a zažijte historické dojmy staré 50 let.",
                de: "<b>Die Straßenbahn</b> in Dresden in den 1970er-Jahren"
                + "<br>Nicht einmal 2 Groschen 20 Pfennige (2 Groschen) kostete bis 1990 die Einzelfahrkarte für eine Fahrt mit der Straßenbahn oder mit dem Bus quer durch Dresden."
                + "<br>Das Jubiläum \"100 Jahre Straßenbahn in Dresden\" war 1972 der Anlass, einen 8-mm-Film über den damaligen Straßenbahnbetrieb zu drehen. Steigen sie ein und erleben sie historische Eindrücke, die nunmehr 50 Jahre zurückliegen.",
                km: "<b>រថភ្លើង</b>នៅ Dresden ក្នុងទសវត្សរ៍ឆ្នាំ 1970"
                + "<br>រហូតដល់ឆ្នាំ 1990 សំបុត្រតែមួយសម្រាប់ការជិះរថភ្លើង ឬឡានក្រុងឆ្លងកាត់ទីក្រុង Dresden មិនមានតម្លៃសូម្បីតែ 2 groschen 20 pfennigs (2 groschen)។"
                + "<br>នៅឆ្នាំ 1972 ខួប \"100 ឆ្នាំនៃរថភ្លើងនៅ Dresden\" គឺជាហេតុផលដើម្បីធ្វើខ្សែភាពយន្ត 8 មីលីម៉ែត្រអំពីប្រតិបត្តិការរថភ្លើងនៅពេលនោះ។ ចូលទៅ និងទទួលយកចំណាប់អារម្មណ៍ជាប្រវត្តិសាស្ត្រដែលមានអាយុកាល 50 ឆ្នាំ។",
            },
            type: 'youtube',
            videoId: '1AldOVnZ9F4',
        },
    },

    {
        title: "Baranec",
        date: "2023/3/1",
        type: 'video',
        content: {
            type: 'facebook',
            videoId: '6613821091962589',
        },
    },

    {
        title: "Facebook video",
        date: "2013/3/3",
        type: 'video',
        content: {
            text: {
                cs: "<b>Výstava „Pohyblivý obraz ve městě“ - Galerie kritiků</b>"
                + "<br>Připravili jsme se pro vás report naší aktuální výstavy „Pohyblivý obraz ve městě“."
                + "<br>Lucie Svobodová - Edita Pattová",
            },
            type: 'facebook',
            videoId: '268294240880514',
        },
    },

    {
        title: {
            cs: "Demo galerie",
            en: "Demo gallery",
            de: "Demo-Galerie",
            km: "វិចិត្រសាលសាកល្បង",
        },
        date: "2023/8/8",
        type: 'gallery',
        content: {
            text: {
                cs: "<p><b>Úvodní blábolivý popis</b></p>"
                    + "<p>" + loremIpsum + "</p>",
                en: "<p><b>Talkative intro</b></p>"
                    + "<p>" + loremIpsum + "</p>",
                de: "<p><b>Gesprächiges Intro</b></p>"
                    + "<p>" + loremIpsum + "</p>",
                km: "<p><b>ការណែនាំវែងពេក</b></p>"
                    + "<p>" + loremIpsum + "</p>",
            },
            style: Models.GALLERY_PREVIEW | Models.GALLERY_TITLE,
            aspectRatio: 16 / 9,
            items: [
                {
                    title: {
                        cs: 'Historicky první vobrázek',
                        en: 'Historically the first picture',
                        de: 'Historisch das erste Bild',
                        km: 'រូបភាពដំបូង',
                    },
                    description: {
                        cs: 'Popis prvního vobrásku',
                        en: 'Description of the first image',
                        de: 'Beschreibung des ersten Bildes',
                        km: 'ការពិពណ៌នាអំពីរូបភាពទីមួយ',
                    },
                    src: '/assets/img/honeycomb-1b3eb081703672c9c865cb6c82c1ea50.jpg',
                },
                {
                    title: {
                        cs: 'Druhý obrázek',
                        en: 'Second picture',
                        de: 'Zweite Bild',
                        km: 'រូបភាពទីពីរ',
                    },
                    // title: // can be undefined
                    // description: // can be undefined
                    src: '/assets/img/honey-bb5f2c47bd61f789249b0defb6f03acc.jpg',
                },
            ],
        },
    },
    {
        title: {
            cs: "Demo fotka",
            en: "Demo photo",
            de: "Demofoto",
            km: "រូបថតគំរូ",
        },
        date: "2023/7/8",
        type: 'photo',
        content: {
            title: {
                cs: 'Jedna fotka',
                en: "Single photo",
                de: 'Einzelfoto',
                km: "រូបថតតែមួយ",
            },
            description: {
                cs: 'Popis fotky',
                en: 'Photo description',
                de: 'Einzelfoto',
            },
            src: '/assets/img/pokus.png',
        },
    },
    
    {
        title: {
            cs: "Demo galerie - zelenej skřet",
            en: "Demo galery - green goblin",
            de: "Demo-Galerie – grüner Kobold",
            km: "វិចិត្រសាលសាកល្បង - skřet ពណ៌បៃតង",
        },
        date: "1988/7/7",
        type: 'gallery',
        content: {
            text: {
                cs: "Dvakrát Rákosníček - s puntíkama",
                en: "Twice Rákosníček - with dots",
                de: "Zweimal Rákosníček – mit Punkten",
            },
            style: Models.GALLERY_INDICATORS,
            aspectRatio: 16 / 9,
            items: [
                {
                    title: {
                        cs: 'Zelenej Rákosníček',
                        en: 'Green Rákosníček',
                        de: 'Grüner Rákosníček',
                    },
                    description: 'Rákosníček',
                    src: '/assets/img/rakosnicek-color.png',
                },
                {
                    // title: // can be undefined
                    // description: // can be undefined
                    src: '/assets/img/rakosnicek-bw.png',
                },
            ],
        },
    },

    {
        title: {
            cs: "Demo pohadka",
            en: "Demo fairy tale",
            de: "Demo-Märchen",
        },
        date: "2002/8/16",
        type: 'gallery',
        content: {
            text: 'pohadka!',
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    src: '/assets/img/honeycomb-1b3eb081703672c9c865cb6c82c1ea50.jpg',
                },
                {
                    src: '/assets/img/honey-bb5f2c47bd61f789249b0defb6f03acc.jpg',
                },
            ],
        },
    },

];


export const textPohadka: string = ""
             + "Šel se jednou stařík projít k řece a uslyšel křik. V řece se topil kupec, křičel o pomoc a nemohl se dostat na břeh. Stařík přispěchal, vzal dlouhou větev a pomohl kupci z vody ven. Kupec nevěděl, jak se staříkovi odvděčit, tak ho pozval k sobě domů do města, pohostil ho a daroval mu kus zlata velký jako koňská hlava."
    + "<br>" + ""
    + "<br>" + "Stařík vzal zlato a šel domů. Naproti němu se objevil koňař celé stádo koni žene:"
    + "<br>" + "„Vítej, staříku, odkud tě bůh vede?“"
    + "<br>" + "„Z města od bohatého kupce.“"
    + "<br>" + "„A co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„Dej mi zlato a vyber si nejlepšího koně!“"
    + "<br>" + "Vybral si stařík nejlepšího koně, poděkoval a šel svou cestou."
    + "<br>" + ""
    + "<br>" + "Jde stařík, a naproti němu pastucha voly žene:"
    + "<br>" + "„Vítej, staříku, odkud tě bůh vede?“"
    + "<br>" + "„Z města od kupce.“"
    + "<br>" + "„A co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„A kde je to zlato?“"
    + "<br>" + "„Vyměnil jsem ho za koně!“"
    + "<br>" + "„Vyměň mi koně za vola, kterého chceš!“"
    + "<br>" + "Stařík vybral si vola, poděkoval a šel svou cestou."
    + "<br>" + ""
    + "<br>" + "Jde stařeček, a naproti němu ovčák žene ovčí stádo:"
    + "<br>" + "„Vítej, stařečku, odkud tě bůh vede?“"
    + "<br>" + "„Od bohatého kupce z města.“"
    + "<br>" + "„A co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„A kde je to zlato?“"
    + "<br>" + "„Vyměnil jsem za koně.“"
    + "<br>" + "„A kůň kde?“"
    + "<br>" + "„Vyměnil jsem za vola.“"
    + "<br>" + "„Vyměň mi vola za berana, kterého chceš!“"
    + "<br>" + "Vzal stařík nejlepšího berana, poděkoval a šel svou cestou."
    + "<br>" + ""
    + "<br>" + "Jde stařík, a naproti němu pastýř žene vepře: zádech:"
    + "<br>" + "„Vítej, staříku, kdes byl?“"
    + "<br>" + "„V městě, u bohatého kupce.“"
    + "<br>" + "„Co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„A kde je to zlato?“"
    + "<br>" + "„Vyměnil jsem za koně.“"
    + "<br>" + "„A kůň kde?“"
    + "<br>" + "„Vyměnil jsem za vola.“"
    + "<br>" + "„A vůl kde?“"
    + "<br>" + "„Vyměnil jsem za berana.“"
    + "<br>" + "„Dej mi berana a vezmi si nejlepšího vepře?“"
    + "<br>" + "Vybral si stařík vepříka, poděkoval pastýřovi a šel svou cestou."
    + "<br>" + ""
    + "<br>" + "Jde stařík, a naproti němu podomní obchodník s košem na zádech:"
    + "<br>" + "„Vítej, staříku! Odkud jdeš?“"
    + "<br>" + "„Od kupce, z města.“"
    + "<br>" + "„A co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„A kde je to zlato?“"
    + "<br>" + "„Vyměnil jsem za koně.“"
    + "<br>" + "„A kůň kde?“"
    + "<br>" + "„Vyměnil jsem za vola.“"
    + "<br>" + "„A vůl kde?“"
    + "<br>" + "„Vyměnil jsem za berana.“"
    + "<br>" + "„A beran kde?“"
    + "<br>" + "„Vyměnil jsem za vepře.“"
    + "<br>" + "„Vyměň mi vepříka za jehlu a vyber si, kterou chceš.“"
    + "<br>" + "Vybral si stařík nejpěknější jehlu, poděkoval a šel domů. Přišel stařík domů, přelézal přes plot a ztratil jehlu."
    + "<br>" + ""
    + "<br>" + "Vyběhla stařenka naproti starouškovi: „Ach můj holoubku! Já bych se tu bez tebe byla utrápila. Nu, vypravuj, byls u kupce?“"
    + "<br>" + "„Byl.“"
    + "<br>" + "„A co ti kupec dal?“"
    + "<br>" + "„Kus zlata jako koňská hlava.“"
    + "<br>" + "„A kde je to zlato?“"
    + "<br>" + "„Vyměnil jsem ho za koně.“"
    + "<br>" + "„A kůň kde?“"
    + "<br>" + "„Vyměnil jsem za vola.“"
    + "<br>" + "„A kde vůl?“"
    + "<br>" + "„Vyměnil jsem za berana.“"
    + "<br>" + "„A beran kde?“"
    + "<br>" + "„Vyměnil jsem za vepříka.“"
    + "<br>" + "„A vepřík kde?“"
    + "<br>" + "„Vyměnil jsem za jehlu. Chtěl jsem ti, stařenko, přinésti dáreček, přelézal jsem přes plot a ztratil jehlu.“"
    + "<br>" + "„Nu, chválabohu, můj holoubku, žes ty sám ses vrátil. Pojďme do jizby večeřet!“"
    + "<br>" + ""
    + "<br>" + "A nyní žije staříček se stařenkou šťastni i bez zlata."
    ;
