import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';  

@Component({
    selector: 'home-card',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './home-card.html',
    styleUrl: './home-card.scss',
})

export class HomeCard {
    bgImage: string | null = null;
    @Input() url: string | null = null;
    @Input() set background(value: string | null) {
        if (value) {
            this.bgImage = value;
        }
    }
    getBgStyle() {
        let style = '';
        if (this.bgImage) {
            style += `background-image: url("${this.bgImage}");`;
        }
        return style;
    }
}