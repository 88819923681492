<div class='contact-page-outer'>
  <div class='contact-page-inner'>

    <div class="contact-content">
      <div class="photo">
        <img class='menu-logo' src="/assets/img/e-art-contact.jpg" alt="E-art contact">
      </div>
      <div class="text">
        <div>Edita Pattová</div>
        <div><i class='pi pi-fw pi-home'></i>: {{ 'contact.address' | translate }}</div>
        <div><i class='pi pi-fw pi-phone'></i>:  +420&nbsp;605489130</div>
        <div><i class='pi pi-fw pi-at'></i>: <a href="mailto:e.pattova@gmail.com">e.pattova{{'@'}}gmail.com</a></div>
      </div>
    </div>

    <div *ngIf='isDevMode'>
      <font-samples></font-samples>
    </div>
    <div *ngIf='!isDevMode'>
      <rakosnicek></rakosnicek>
    </div>

  </div>  
</div>