import { WebPost } from '../models/models';
import * as Models from '../models/models';
import { transOilOnCanvas } from '../trans/multilang.types';

export const pageItemsPainting: WebPost[] = [
       
    {
        title: {
            en: "Seattle",
            cs: "Seattle",
            de: "Seattle",
        },
        date: "2020",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil painting on canvas</i>",
                cs: "<i>olejomalba na plátně</i>",
                de: "<i>Öl auf Leinwand</i>"
            },
            style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
            items: [
                {
                    title: {
                        en: "Seattle I.",
                        cs: "Seattle I.",
                        de: "Seattle I.",
                    },
                    
                    description: {
                        en: "oil on canvas, 70x100 cm",
                        cs: "olejomalba na plátně, 70 x 100 cm",
                        de: "Öl auf Leinwand, 70 x 100 cm",
                    },
                    
                    src: '/assets/img/Paintings/Seattle_2020/Seattle1.jpg',
                },
                {
                    title: {
                        en: "Seattle II.",
                        cs: "Seattle II.",
                        de: "Seattle II.",
                    },
                    
                    description: {
                        en: "oil on canvas, 75x90 cm",
                        cs: "olejomalba na plátně, 75 x 90 cm",
                        de: "Öl auf Leinwand, 75 x 90 cm",
                    },
                    
                    src: '/assets/img/Paintings/Seattle_2020/Seattle2.jpg',
                },
                {
                    title: {
                        en: "Seattle III.",
                        cs: "Seattle III.",
                        de: "Seattle III.",
                    },
                    
                    description: {
                        en: "oil on canvas, 55 x 55 cm",
                        cs: "olejomalba na plátně, 55 x 55 cm",
                        de: "Öl auf Leinwand, 55 x 55 cm",
                    },
                    
                    src: '/assets/img/Paintings/Seattle_2020/Seattle3.jpg',
                },
            ],
        },
    },
    {
        title: "Facebook",
        date: "2012 - 2020",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil on canvas 100 x 50 cm</i>" + "<br>"
                + 'How do we present our personalities and lives in public? Do we behave differently online and in the "real" world? And what exactly is the "real" world? Our contributions or comments on Facebook are also read by "real" people after all..'
                + ' The Edita\'s "Facebook" project examines the phenomenon of this medium and self-presentation. What exactly our profile tells about us? We mostly share only positive feelings or successes, photos which are either funny or sexy, we tend to create our better "avatar".' 
                + ' It is not about critics however, it is rather a social study; Edita is also an active Facebook user after all. She has painted'
                + ' these canvases according to real photos and chats on Facebook, thus bringing them back to the "real" world. And some reactions have been rather surprising. Some asked to change their username, others were pleased by becoming a part of this artwork..'
                + "<br>" + "Martin Fojtek, Gallery Fotografic",
                cs: "<i>olej na plátně 100 x 50 cm</i>" + "<br>"
                + 'Jak prezentujeme svou osobnost a život na veřejnosti? Chováme se jinak online a jinak v „reálném“ světě? A co je vlastně „reálný“ svět? Vždyť na internetu si naše příspěvky a komentáře také prohlížejí „reální“ existující lidé..' 
                + ' Projekt Edity Pattové „Facebook“ zkoumá fenomén tohoto média a sebeprezentace. Co o nás vypovídá náš profil? Většinou sdílíme'
                + ' jen pozitivní pocity a úspěchy. Fotografie, které nám připadají vtipné, kde vypadáme sexy, vytváříme si tak svého lepšího avatara. Nejde o kritiku tohoto média, jde spíše o sociální průzkum, koneckonců autorka je také uživatel.' 
                + ' Edita namalovala tato plátna podle reálných fotografií a chatů na Facebooku, tím tyto příspěvky vrátila zpět do „reálného“ světa a některé reakce překvapily. Někteří požadovali změnu uživatelského jména, jiní zase měli radost, že se stanou součástí uměleckého díla..' 
                + "<br>" + "Martin Fojtek, Galerie Fotografic",
                de: "<i>Öl auf Leinwand 100 x 50 cm</i>" + "<br>"
                + 'Wie präsentieren wir unsere Persönlichkeit und Leben in der Öffentlichkeit? Unterscheiden wir zwischen online und der "realen" Welt? Was genau ist die "reale" Welt? Unsere Beiträge und Kommentare auf Facebook werden trotzdem immer noch von "realen" Personen gelesen.' 
                + ' Das Edita`s "Facebook" Projekt prüft das Phänomen dieses Mediums und der Eigendarstellung. Was sagen die Profile genau über'
                + ' uns aus? Meistens teilen wir nur positive Gefühle oder Erfolge, lustige oder sexy Bilder, wir neige dazu unser besseres "Avatar"'
                + ' (oder virtuelles Ich) zu erschaffen. Es geht nicht um die Kritik, es soll vielmehr eine Sozialstudie sein; Edita ist trotzdem immer'
                + ' noch ein aktiver Facebook Benutzer. Sie hat die Gemälde nach realen Bilder und Chats auf Facebook gezeichnet um sie in die "reale" Welt zurückzubringen. Einige Reaktionen waren sehr überraschend. Einige habe aufgefordert, dass ihr Benutzernamen geändert werden, andere waren erfreut ein Teil von diesem Kunstwerk zu werden.' 
                + "<br>" + "Martin Fojtek, Galerie Fotografic",
            },
            style: Models.GALLERY_TITLE | Models.AUTO_ASPECT,
            items: [
                {
                    title: 'Ludmila, 2020',
                    description: transOilOnCanvas('100 x 50 cm'),
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_0.jpg',
                },
                {
                    title: 'Edita, 2015',
                    description: transOilOnCanvas('100 x 50 cm'),
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_1.jpg',
                },
                {
                    title: 'Stili, 2014',
                    description: transOilOnCanvas('100 x 50 cm'),
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_2.jpg',
                },
                {
                    title: 'Ludmila, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_3.jpg',
                },
                {
                    title: 'Jan, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_4.jpg',
                },
                {
                    title: 'Kristy, 2012',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_5.jpg',
                },
                {
                    title: 'Stili, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_6.jpg',
                },
                {
                    title: 'Jan, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_7.jpg',
                },
                {
                    title: 'Andrejka, 2012',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_8.jpg',
                },
                {
                    title: 'Emil, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_9.jpg',
                },
                {
                    title: 'Lulca, 2012',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_10.jpg',
                },
                {
                    title: 'Zuzka, 2013',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_11.jpg',
                },
                {
                    title: 'Nada, 2014',
                    description: 'oil on canvas 100 x 50 cm',
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_12.jpg',
                },
                {
                    title: {
                        en: "Gallery of Art Critics",
                        cs: "Galerie Kritiků",
                        de: "Galerie der Kritiker",
                    },
                    
                    description: {
                        en: "view from the gallery",
                        cs: "pohled do galerie",
                        de: "Einblick in die Installation",
                    },
                    
                    src: '/assets/img/Paintings/Facebook_2012-2023/fb_13.jpg',
                },
            ],
        },
    },
    {
        title: {
            en: "Alteration of Function of Vehicle",
            cs: "Změna účelu vozidla",
            de: "Veränderung des Fahrzeugzwecks",
        },
        date: "2010",
        type: 'gallery',
        content: {
            text: {
            en: "oil painting on engine bonnet, 150x150 cm" + "<br>"
                + "In Czech Republic there are two possibilities how to unregister a vehicle. First option is to get a confirmation of “ecological disposal“. Second is called: “Change of the vehicle purpose“, this way it is possible to unregister a vehicle, if one proves, that it changed"
                + " its purpose. Therefore people usually bring to the authority photographs of a wreck, which is used as a hen house or as a kennel or something similar. I decided to transform the wreck into an artwork."
                + " The paint is dashed directly on the crashed engine bonnet, traditionally with oil colours. Its original surface is glazing like a mirror, so I installed the bonnet into our room and painted everything I saw reflected in it including the reflection of myself. The bonnet undercoat is black metallic and shows through in the dark and black parts of the painting." 
                + " When installed in a gallery the black metallic is also reflecting parts and lights of the surrounding, so than there are two reflections at once.",
            cs: "<i>olejomalba na kapotě, 150 x 150 cm</i>" + "<br>"
                + 'Když se chcete zbavit vraku potřebujete papír o „ekologické likvidaci”. Existuje zákon s názvem „Změna účelu vozidla”. Díky tomu je možné auto odhlásit, pokud člověk prokáže, že změnilo účel. K tomu obvykle lidé nosí na úřad fotografie, na nichž je vrak, který slouží jako kurník nebo psí bouda a podobně. Já jsem se rozhodla přetvořit vrak na umělecké dílo.' 
                + ' Malba je nanesena přímo na povrch kapoty od BMW, které jsem nabourala, přičemž černou tvoří černá metalíza podkladu. Povrch'
                + ' se leskne jako zrcadlo a v černých částech se neustále odrážejí různé věci nebo světla. Při práci na projektu jsem kapotu přenesla z dvorku do pokoje a namalovala jsem na ni vše co se v ní odráželo včetně sebe. Pokud však výsledný obraz přenesu do jiného prostředí, odrážený obraz se změní. Jsou to tedy dva obrazy v jednom.',
            de: "<i>Öl an der Motorhaube, 150 x 150 cm</i>" + "<br>"
                + 'Wenn man das Autowrack von der Evidenz herausnehmen will, muss man die Bestätigung über ökologische Liquidation vorlegen. Es gibt zweite Möglichkeit im Dekret “Veränderung des Fahrzeugzwecks”. Dazu bringt man gewöhnlich zum Amt Fotos,'
                + ' dass das Wrack wie Hühnerstall, Hundehütte, Werbeanzeiger und dergleichen dient. Ich entschied mich aus manchen Wrackteilen ein Kunstwerk zu schaffen.' 
                + ' Das Bild wurde mit Ölfarben direkt an die vordere Motorhaube gemalt. Die schwarze Farbe wurde mit schwarz Metallic der Unterlage gebildet. Die Oberfläche ist glänzend und in schwarzen Teilen reflektieren verschiedene Gegenstände und Lichter. Ich malte alles, was ich im Raum mitsamt mir sah. Wenn das resultierende Bild in einen anderen Raum kommt, wiederspiegelt sich dort das zweite Bild, so sieht man zwei Bilder in einem.'
            },
            style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
            items: [
                {
                    title: {
                        en: "Alteration of Function of Vehicle",
                        cs: "Změna účelu vozidla",
                        de: "Veränderung des Fahrzeugzwecks",
                    },
                    
                    description: {
                        en: "oil painting on engine bonnet, 150x150 cm",
                        cs: "olejomalba na kapotě, 150 x 150 cm",
                        de: "Öl an der Motorhaube, 150 x 150 cm",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/change1 (1).jpg',
                },
                {
                    title: {
                        en: "Alteration of Function of Vehicle",
                        cs: "Změna účelu vozidla",
                        de: "Veränderung des Fahrzeugzwecks",
                    },
                    
                    description: {
                        en: "oil painting on engine bonnet, 150x150 cm",
                        cs: "olejomalba na kapotě, 150 x 150 cm",
                        de: "Öl an der Motorhaube, 150 x 150 cm",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/change2.jpg',
                },
                {
                    title: {
                        en: "Alteration of Function of Vehicle, 2011",
                        cs: "Změna účelu vozidla, 2011",
                        de: "Veränderung des Fahrzeugzwecks, 2011",
                    },
                    
                    description: {
                        en: "oil painting on the right side fender, 100 x 50 cm",
                        cs: "olejomalba na pravém blatníku, 100 x 50 cm",
                        de: "Öl an dem rechten Seitenkotflügel, 100 x 50 cm",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/change3.jpg',
                },
                {
                    title: {
                        en: "Alteration of Function of Vehicle, 2011",
                        cs: "Změna účelu vozidla, 2011",
                        de: "Veränderung des Fahrzeugzwecks, 2011",
                    },
                    
                    description: {
                        en: "oil painting on the left side fender, 100 x 50 cm",
                        cs: "olejomalba na levém blatníku, 100 x 50 cm",
                        de: "Öl an dem linken Seitenkotflügel, 100 x 50 cm",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/change4.jpg',
                },
                {
                    title: {
                        en: "Alteration of Function of Vehicle, 2011",
                        cs: "Změna účelu vozidla, 2011",
                        de: "Veränderung des Fahrzeugzwecks, 2011",
                    },
                    
                    description: {
                        en: "oil painting on the side fender, 100 x 50 cm, detail",
                        cs: "olejomalba na bočním blatníku, 100 x 50 cm, detail",
                        de: "Öl an dem Seitenkotflügel, 100 x 50 cm, Detail",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/change5.jpg',
                },
                {
                    title: {
                        en: "Alteration of Function of Vehicle",
                        cs: "Změna účelu vozidla",
                        de: "Veränderung des Fahrzeugzwecks",
                    },
                    
                    description: {
                        en: "oil painting on engine bonnet, 150 x 150 cm, detail",
                        cs: "olejomalba na kapotě, 150 x 150 cm, detail",
                        de: "Öl an der Motorhaube, 150 x 150 cm, Detail",
                    },
                    
                    src: '/assets/img/Paintings/Haubna_2010/Malba na haubne_svetla zboku1_2010_edited.jpg',
                },
            ],
        },
    },

    {
        title: {
            en: "Story about a high voltage pole",
            cs: "Příběh o sloupu vysokého napětí",
            de: "Geschichte über Hochspannungssäule",
        },        
        date: "2010",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil on canvas, frame</i>" + "<br>"
                + "I painted these canvases for a company CAO Central Allocation Munich. After a discussion over drawings came out this story"
                + " about the power pole, who starts to be bored on the meadow one day. He manages to free himself and runs to a pub. However,"
                + " he doesn't like the music there, so he decides to become a DJ. He takes some girls with and returns back to his home place. There is already a commotion caused by technicians looking for him. The power pole returns, begins to play, so they all celebrate,"
                + " the end of working hours for today.",
                cs: "<i>olej na plátně, rám</i>" + "<br>"
                + 'Tyto obrazy jsem namalovala pro elektrárenskou firmu CAO Central Allocation Munich. Po diskuzi nad kresebnýmy návrhy vznikl příběh o sloupu, který se začne jednoho dne na louce nudit. Osvobodí se a uteče do hospody. Tam ale hraje nudná kapela. Rozhodne se,'
                + ' že se stane DJem. Vrátí se na louku i s holkama z hospody. Tam je již pozdvižení a hledají ho technici. Sloup se vrátil, začal hrát,'
                + ' tak spolu všichni oslaví, že už mají padla.',
                de: "<i>Öl auf Leinwand, Rahmen</i>" + "<br>"
                + 'Diese Bilder habe ich für eine Kraftwerksfirma CAO Central Allocation Munich gemalt. Nach eine Diskussion über die Konzepte entstand eine Geschichte über eine Säule, die sich einen Tag auf der Wiese zu langweilen beginnt.' 
                + ' Er befreit sich und entläuft in die Kneipe. Dort spielt aber eine langweilige Band. Er entscheidet sich, dass er DJ wird. Er kommt auf die Wiese mit den Mädchen aus der Kneipe zurück. Dort gibt es schon einen grossen Aufbruch und die Techniker suchen ihn. Die Säule ist zurück, alle haben Feierabend und können feiern.'
            },
            
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: '---',
                    description: 'oil on canvas, 55 x 55cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup1.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 55 x 55cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup2.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 55 x 55cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup3.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 55 x 55cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup4.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 55 x 55cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup5.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 75 x 75cm',
                    src: '/assets/img/Paintings/Sloup_2011/sloup7.jpg',
                },
            ],
        },
    },
    {
        title: {
            en: "Symbionts",
            cs: "Symbionti",
            de: "Symbionten",
        },
        date: "2005",
        type: 'gallery',
        content: {
            text: "Galerie s indikatorem",
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: '---',
                    description: 'oil on canvas, 50 x 50cm',
                    src: '/assets/img/Paintings/Lampy_2005-06/lampy1.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 50 x 50cm',
                    src: '/assets/img/Paintings/Lampy_2005-06/lampy2.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 50 x 50cm',
                    src: '/assets/img/Paintings/Lampy_2005-06/lampy3.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 50 x 50cm',
                    src: '/assets/img/Paintings/Lampy_2005-06/lampy4.jpg',
                },
            ],
        },
    },

    {
        title: {
            en: "How I ate a ladybug",
            cs: "Jak jsem snědla berušku",
            de: "Wie ich Sonnenkäfer gegessen habe",
        },
        date: "2005",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil on canvas, frame</i>" + "<br>"
                + "You certainly also have the feeling sometimes, that it would be best to swim somewhere far away from the city. Perharps as a fish, yeah, but what about if you have stayed on that place you are and the fish would be a bit bigger=than you would stay to flap"
                + " on the ground and hope, that water is closer than you think. Really, I ate a ladybug and after its reinkarnation into a fox, it bit me and those transformed into a whale, none cared about me, I was held off there and at the end someone spilled beer at me.= I wanted to go away!" 
                + " Than suddenly they took me away. It was tight and dirty there …, but at the end … —Hurrrra! -sea, freedom, whales!",
                cs: "<i>olej na plátně, rám</i>" + "<br>"
                + 'Znáte ten pocit, kdy byste nejradši z toho města chtěli odplavat někam daleko? Třeba jako rybička, no jo, ale co kdyby jste zůstali'
                + ' na tom místě kde jste a navíc by ta ryba pěkně vyrostla=asi byste se plácali na suchu a doufali, že voda je blíž než si myslíte.'
                + ' Tak něco takového jsem prožila ve svém souboru JAK JSEM SNĚDLA BERUŠKU, opravdu, snědla jsem berušku, ta mě po své reinkarnaci v lišku za trest kousne a já se proměním na velrybu, nikdo si mě nevšímá, asi tam leknu a navíc mě polili pivem=chci pryč!'
                + ' Pak mě někam nesou a … je tu hrozně těsno, puch …, ale nakonec … Hurá! Moře, volnost, velrybáci!!!',
                de: "<i>Öl auf Leinwand, Rahmen</i>" + "<br>"
                + 'Sie kennen das Gefühl, wenn sie am besten aus dieser Stadt irgendwo weit wegschwimmen möchten. Vielleicht wie ein Fischen,'
                + ' na ja, aber was wenn sie an diesem Ort wo sind bleiben würden, und als Fisch heranwachsen würden = sie würden sich'
                + ' auf dem Festland plätschern und hoffen, dass das Wasser näher ist als sie denken. Also so etwas habe ich im Zyklus “Wie ich Sonnenkäfer gegessen habe” erlebt. Wirklich, ich habe einen Sonnenkäfer gegessen, der hat mich nach seiner Reinkarnation'
                + ' auf einen Fuchs als Strafe gebissen, ich werde mich in einen Walfisch verändern, niemand achtet auf mich, ich werde dort ersticken, dazu hat man mich mit Bier übergossen. = Ich will weg!'
                + ' Dann werde ich irgendwohin getragen, hier ist es furchtbar eng, Geruch …, aber am Ende … Hurra! — Meer, Freiheit, Walfische!'
            },
            
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: '---',
                    description: 'oil on canvas, 135 x 200cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale1.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 35 x 65 cm and 70 x 80cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale2.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 135 x 90cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale3.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 135 x 65cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale4.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 150 x 150cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale5.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 50 x 70cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale6.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 130 x 90cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale7.jpg',
                },
                {
                    title: '---',
                    description: 'oil on canvas, 130 x 145cm',
                    src: '/assets/img/Paintings/Velryby_2005/whale8.jpg',
                },
            ],
        },
    },

    {
        title: {
            en: "Hydrant in the forest",
            cs: "Hydrant v lese",
            de: "Hydranten im Wald",
        },
        date: "2003 - 06",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil on canvas, frame</i>" + "<br>"
                + "This episode introduces us into a free compiled fairy tale about a hydrant, who is discontent and looking for satisfaction. This is"
                + " the reason why he leaves for the nature, which seems incomprehensible to him at first, but finally he finds his place on Earth.",
                cs: "<i>olej na plátně, rám</i>" + "<br>"
                + 'Tento soubor představuje volně zpracovaný pohádkový příběh o hydrantově nespokojenosti a hledání uspokojení. Proto odejde'
                + ' do přírody, která se zprvu jeví nepochopitelná, ale nakonec najde klid v sobě, tudíž i své místo na Zemi. Některé obrazy jsou viděny složeným pohledem komára a tím obohacují naše zažité lidské vnímání.',
                de: "<i>Öl auf Leinwand, Rahmen</i>" + "<br>"
                + 'Dieser Zyklus stellt eine frei bearbeitete Märchengeschichte über Hydrants Unzufriedenheit und seinem Suchen nach Befriedigung dar.'
                + ' Deswegen flieht er in die Natur, die zuerst als Ungreifbar erscheint, aber der Hydrant findet am Schluss seine innere Ruhe und damit auch seinen Platz in der Welt.',
            },
            
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: {
                        en: "Argueing hydrants, 2003",
                        cs: "Hádající se hydranti, 2003",
                        de: "Streitende Hydranten, 2003",
                    },
                    
                    description: 'oil on canvas, 135 x 200cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H1.jpg',
                },
                {
                    title: {
                        en: "Crying hydrant, 2004",
                        cs: "Plačící hydrant, 2004",
                        de: "Weinender Hydrant, 2004",
                    },
                    
                    description: 'oil on canvas, 63 x 56cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H10.jpg',
                },
                {
                    title: {
                        en: "Singing willow, 2003",
                        cs: "Zpívající vrba, 2003",
                        de: "Singende Weide, 2003",
                    },
                    
                    description: 'oil on canvas, 135 x 200cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H11.jpg',
                },
                {
                    title: {
                        en: "Hydrant`s ears are roling, 2004",
                        cs: "Hydrantovi se rolují uši, 2004",
                        de: "Hydrantenohren rollen, 2004",
                    },
                    
                    description: 'oil on canvas, 70 x 90cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H12.jpg',
                },
                {
                    title: {
                        en: "Hopping hydrant, 2003",
                        cs: "Hopkající hydrant, 2003",
                        de: "Hopfenhydrant, 2003",
                    },
                    
                    description: 'oil on canvas, 135 x 65cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H13.jpg',
                },
                {
                    title: {
                        en: "Predators I., 2004",
                        cs: "Predátoři I., 2004",
                        de: "Raubtiere I., 2004",
                    },
                    description: 'oil on canvas, 70 x 90cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H14.jpg',
                },
                {
                    title: {
                        en: "Predators II., 2004",
                        cs: "Predátoři II., 2004",
                        de: "Raubtiere II., 2004",
                    },
                    description: 'oil on canvas, 70 x 80cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H15.jpg',
                },
                {
                    title: {
                        en: "Predators III., 2004",
                        cs: "Predátoři III., 2004",
                        de: "Raubtiere III., 2004",
                    },
                    description: 'oil on canvas, 65 x 90cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H16.jpg',
                },
                {
                    title: {
                        en: "Predators IV., 2005",
                        cs: "Predátoři IV., 2005",
                        de: "Raubtiere IV., 2005",
                    },
                    description: 'oil on canvas, 65 x 95cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H17.jpg',
                },
                {
                    title: {
                        en: "Predators V., 2006",
                        cs: "Predátoři V., 2006",
                        de: "Raubtiere V., 2006",
                    },
                    description: 'oil on canvas, 40 x 50cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H2.jpg',
                },
                {
                    title: {
                        en: "Hydrant and the Willow I., 2006",
                        cs: "Hydrant a vrba I., 2006",
                        de: "Hydrant und die Weide I., 2006",
                    },
                    description: 'oil on canvas, 70 x 85cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H3.jpg',
                },
                {
                    title: {
                        en: "Hydrant and the Willow II., 2006",
                        cs: "Hydrant a vrba II., 2006",
                        de: "Hydrant und die Weide II., 2006",
                    },
                    description: 'oil on canvas, 40 x 50cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H4.jpg',
                },
                {
                    title: {
                        en: "Hydrant by the river, 2005",
                        cs: "Hydrant u řeky, 2005",
                        de: "Hydrant am Fluss, 2005",
                    },
                    description: 'oil on canvas, 70 x 86cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H5.jpg',
                },
                {
                    title: {
                        en: "Hydrant with bubles, 2005",
                        cs: "Hydrant s bublinami, 2005",
                        de: "Hydrant mit Blasen, 2005",
                    },
                    description: 'oil on canvas, 120 x 80cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H6.jpg',
                },
                {
                    title: {
                        en: "Hydrant in love I., 2005",
                        cs: "Zamilovaný hydrant I., 2005",
                        de: "Hydrant in Love I., 2005",
                    },
                    description: 'oil on canvas, 65 x 65cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H7.jpg',
                },
                {
                    title: {
                        en: "Hydrant in love II., 2006",
                        cs: "Zamilovaný hydrant II., 2006",
                        de: "Hydrant in Love I., 2006",
                    },
                    description: 'oil on canvas, 70 x 70cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H8.jpg',
                },
                {
                    title: {
                        en: "H+B=VSL, 2006",
                        cs: "H+B=VSL, 2006",
                        de: "H+B=VSL, 2006",
                    },
                    description: 'oil on canvas, 40 x 50cm',
                    src: '/assets/img/Paintings/Hydranti_2003-06/H9.jpg',
                },
            ],
        },
    },
    
    
    {
        title: {
            en: "1000 flies and one night",
            cs: "1000 much a jedna noc",
            de: "1000 Fliegen und 1 Nacht",
        },
        date: "2003",
        type: 'gallery',
        content: {
            text: {
                en: "<i>oil on canvas, frame</i>" + "<br>"
                + "Fly! Why have I chosen this odd subject for my paintings? Because this small creature sees us differently. It flies in our apartement," 
                + " it looks into our privacy. Often it just annoying us. Fly sees everything with its own subjectively abstract view. How? Try to think about it. Let imagine yourself in a fly's shoes.",
                cs: "<i>olej na plátně, rám</i>" + "<br>"
                + 'Otravná moucha! Proč jsem si vybrala toto téma jako námět svých obrazů? Protože tento tvor nás vidí jinak. Létá v našem bytě, vidí'
                + ' do našeho soukromí, často nás jen otravuje. Vidí vše svým subjektivně abstraktním pohledem. Jak? Vžijme se do jejího světa …',
                de: "<i>Öl auf Leinwand, Rahmen</i>" + "<br>"
                + 'Lästige Fliege! Warum habe ich dieses Thema als Motiv für meine Bilder gewählt? Weil uns dieses Geschöpft anders sieht.' 
                + ' Es fliegt durch unsere Wohnung, sieht Tief in unsere Privatsphäre, oft nervt sie uns nur. Alles sieht sie durch ihren subjektiven abstrakten Blick. Wie? Versuchen wir uns in ihre Welt hineinzuversetzen …',
            },
            
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: {
                        en: "By the swimming pool",
                        cs: "U bazénu",
                        de: "Am Schwimmbad",
                    },
                    
                    description: 'oil on canvas, 135 x 200cm',
                    src: '/assets/img/Paintings/Mouchy_2003/M1.jpg',
                },
                {
                    title: {
                        en: "Strawberry coctail",
                        cs: "Jahodový koktejl",
                        de: "Erdbeercocktail",
                    },
                    
                    description: 'oil on canvas, 75 x 85cm',
                    src: '/assets/img/Paintings/Mouchy_2003/M2.jpg',
                },
                {
                    title: {
                        en: "Triptych: In front of the mirror",
                        cs: "Triptych: Před zrcadlem",
                        de: "Triptychon: Vor dem Spiegel",
                    },
                    
                    description: 'oil on canvas, 135 x 65cm',
                    src: '/assets/img/Paintings/Mouchy_2003/M3.jpg',
                },
                {
                    title: {
                        en: "Triptych: Focus",
                        cs: "Triptych: Zpozornění",
                        de: "Triptychon: Fokus",
                    },
                    
                    description: 'oil on canvas, 35 x 45cm',
                    src: '/assets/img/Paintings/Mouchy_2003/M4.jpg',
                },
                {
                    title: {
                        en: "Triptych: Elimination",
                        cs: "Triptych: Zneškodnění",
                        de: "Triptychon: Beseitigung",
                    },
                    
                    description: 'oil on canvas, 85 x 45cm',
                    src: '/assets/img/Paintings/Mouchy_2003/M5.jpg',
                },
            ],
        },
    },

    {               
        title: {
            en: "Initiation Rite",
            cs: "Iniciační rituál",
            de: "Übergangsritus",
        },
        date: "2006",
        type: 'gallery',
        content: {
            text: "3x oil on canvas, 50x100 cm",
            style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
            items: [
                {
                    title: 'Initiation Rite',
                    description: 'oil on canvas, 50x100 cm',
                    src: '/assets/img/Paintings/initiation rite/initiation_rite_1.jpg',
                },
                {
                    title: 'Initiation Rite',
                    description: 'oil on canvas, 50x100 cm',
                    src: '/assets/img/Paintings/initiation rite/initiation_rite_2.jpg',
                },
                {
                    title: 'Initiation Rite',
                    description: 'oil on canvas, 50x100 cm',
                    src: '/assets/img/Paintings/initiation rite/initiation_rite_3.jpg',
                },
                
            ],
        },
    },

    {               
        title: {
            en: "Intimacy",
            cs: "Intimita",
            de: "Intimität",
        },
        date: "2004",
        type: 'gallery',
        content: {
            text: "oil on canvas, 40x50 cm",
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i1.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i2.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i3.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i4.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i5.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i6.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i7.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i8.jpg',
                },
                {
                    title: 'Intimacy',
                    description: 'oil on canvas, 40x50 cm',
                    src: '/assets/img/Paintings/Intimita_2004/i9.jpg',
                },
            ],
        },
    },
    {
        title: "More pics",
        date: "2004",
        type: 'gallery',
        content: {
            text: "Galerie s indikatorem",
            style: Models.GALLERY_INDICATORS,
            items: [
                {
                    title: 'Swimmers',
                    description: 'oil on canvas',
                    src: '/assets/img/Paintings/Jine_2003/j1.jpg',
                },
                {
                    title: 'Swimmers',
                    description: 'oil on canvas',
                    src: '/assets/img/Paintings/Jine_2003/j2.jpg',
                },
                {
                    title: 'Pineapple',
                    description: 'oil on canvas 70 x 80cm',
                    src: '/assets/img/Paintings/Jine_2003/j3.jpg',
                },
                {
                    title: 'Unrest',
                    description: 'oil on canvas',
                    src: '/assets/img/Paintings/Jine_2003/j4.jpg',
                },
                {
                    title: 'Unrest',
                    description: 'oil on canvas',
                    src: '/assets/img/Paintings/Jine_2003/j5.jpg',
                },
                {
                    title: 'Unrest',
                    description: 'oil on canvas',
                    src: '/assets/img/Paintings/Jine_2003/j6.jpg',
                },
            ],
        },
    },
    
];
