import { Component } from '@angular/core';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [],
  template: `
    <p>
      Sorry, page not found!
    </p>
    <p>
      <a href='/'>Go to {{environment.title}} home page.</a>
    </p>
  `,
  styles: ``
})
export class PageNotFoundComponent {
  environment = environment;
}
