import { Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { environment } from '../environments/environment';

/* TODO - remove this - only for DEVEL */
import { isDevMode } from '@angular/core';

export const routes: Routes = [
    {
        path: '',
        title: environment.title + " - Home",
        component: HomePageComponent,
        data: {breadcrumb: 'home-breadcrumb'}
    },
    {
        path: 'photo',
        title: environment.title + " - Photos",
        component: ContentPageComponent,
        data: {section: 'photo'}
    },
    {
        path: 'painting',
        title: environment.title + " - Painting",
        component: ContentPageComponent,
        data: {section: 'painting'}
    },
    {
        path: 'installation',
        title: environment.title + " - Installation",
        component: ContentPageComponent,
        data: {section: 'installation'}
    },
    {
        path: 'exhibition',
        title: environment.title + " - Exhibition",
        component: ContentPageComponent,
        data: {section: 'exhibition'}
    },
    {
        path: 'bio',
        title: environment.title + " - Bio",
        component: ContentPageComponent,
        data: {section: 'bio', breadcrumb: 'bio-breadcrumb'}
    },
    {
        path: 'contact',
        title: environment.title + " - Contact",
        component: ContactPageComponent,
        data: {breadcrumb: 'contact'}
    },

    /* TODO - disable this - only for DEVEL */
    ...insertIf(isDevMode(), {
        path: 'demo',
        title: environment.title + " - Demo styles",
        component: ContentPageComponent,
        data: {section: 'demo'}
    },
    {
        path: 'fonty',
        title: environment.title + " - Font preview",
        component: ContactPageComponent,
    }),
    /* end of TODO */

    {
        path: 'home',
        redirectTo: '/',
        pathMatch: 'full'
    }, // redirect to `Home`
    {
        path: '**',
        title: environment.title + ' - Page Not Found',
        component: PageNotFoundComponent
    },  // Wildcard route for a 404 page
];

/* TODO - remove this - only for DEVEL */
function insertIf(condition : any, ...elements : any[]) { // (A)
    return condition ? elements : [];
}