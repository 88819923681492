import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransUnit } from '../trans/multilang.types';

import { YouTubePlayer } from '@angular/youtube-player';
import VimeoPlayer from '@vimeo/player';

import { PostText } from "./post-text";
import { WebVideoCard } from '../models/models';

@Component({
    selector: 'video-card',
    standalone: true,
    imports: [ CommonModule, PostText, YouTubePlayer ],
    templateUrl: './video-card.html',
    styleUrl: './video-card.scss',
})
export class VideoCard {
    text: TransUnit | undefined;
    expanded? : boolean = false;
    videoId: string = "";
    isYoutube: boolean = false;
    isVimeo: boolean = false;
    isFacebook: boolean = false;
    collapsed : boolean = true;

    @Input() set setData(value: WebVideoCard) {
        this.text = value.text;
        this.videoId = value.videoId;
        this.isYoutube = value.type == 'youtube';
        this.isVimeo = value.type == 'vimeo';
        this.isFacebook = value.type == 'facebook';
        this.expanded = value.expanded;
    }
    @ViewChild('vimeoContainer') vimeoContainer: ElementRef | undefined;
    @ViewChild('facebookContainer') facebookContainer: ElementRef | undefined;

    resizeIframe(event: Event) {
        console.log(event.currentTarget);
        console.log(event);
    }

    ngAfterViewInit() {
        if (this.isVimeo) {
            if (this.vimeoContainer) {
                const player = new VimeoPlayer(this.vimeoContainer.nativeElement, { url: this.videoId });
                if (player) {
                    player.on('play', function() {
                        console.log('Vimeo play started');
                    });
                    player.getVideoTitle().then(function(title) {
                        console.log('Vimeo player title:', title);
                    });
                } else {
                    console.log('Vimeo: new Player() failed');
                }
            } else {
                console.log('@ViewChild(\'vimeoContainer\') element missing');
            }
        }
        if (this.isFacebook) {
            if (this.facebookContainer) {
                const src = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D"+this.videoId+"&show_text=false&width=560&t=0";
                this.facebookContainer.nativeElement.src = src;
            } else {
                console.log('@ViewChild(\'facebookContainer\') element missing');
            }
        }
    }
}