import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';

import { HomeCard } from './home-card';


@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [ TranslateModule, HomeCard ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent {
  environment = environment;
  constructor() {}
}
