<a class="home-card-wrapper" [href]="url" oncontextmenu="return false;">
  <div class='bg'>
    <div [style]="getBgStyle()">
    </div>
  </div>
  <div class='lb'>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</a>