import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { MultilangModule } from '../trans/multilang.module';

import { PostText } from "./post-text";
import { ImageModule } from 'primeng/image';
import { WebPhoto } from '../models/models';

@Component({
    selector: 'photo-card',
    standalone: true,
    imports: [ CommonModule, MultilangModule, PostText, ImageModule ],
    templateUrl: './photo-card.html',
    styleUrl: './photo-card.scss',
})
export class PhotoCard {
    data: WebPhoto = {
        title: '',
        description: '',
        src: '',
    }
    collapsed : boolean = true;
    @Input() set setData(value: WebPhoto) {
       this.data = value;
    }
}