import { Component } from '@angular/core';

/* TODO - remove this - only for DEVEL */
import { isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { Rakosnicek } from '../component/rakosnicek';
import { FontSamplesComponent } from './font-samples.component';

@Component({
    selector: 'app-contact-page',
    standalone: true,
    imports: [CommonModule, TranslateModule, FontSamplesComponent, Rakosnicek],
    templateUrl: './contact-page.component.html',
    styleUrl: './contact-page.component.scss',
})
export class ContactPageComponent {
    /* TODO - remove this - only for DEVEL */
    isDevMode: boolean = isDevMode();
}
