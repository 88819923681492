import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule, Menubar } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

/* TODO - remove this - only for DEVEL */
import { isDevMode } from '@angular/core';

import { environment } from '../../environments/environment';
import { ClickOutsideSubmenuDirective } from '../directives/ClickOutsideDirective';
import { LanguageDropdownComponent } from './language-menu';

/* TODO - remove this - only for DEVEL */
function insertIf(condition : any, ...elements : any[]) { // (A)
    return condition ? elements : [];
}

@Component({
    selector: 'app-main-menu',
    standalone: true,
    imports: [MenubarModule, FormsModule, InputTextModule, ButtonModule, LanguageDropdownComponent, CommonModule, TranslateModule, ClickOutsideSubmenuDirective],
    templateUrl: './main-menu.component.html',
    styleUrl: './main-menu.component.scss',
})
export class MainMenuComponent {
    items: MenuItem[] = [];
    selectedLang: string | undefined;
    @ViewChild('mainMenu') mainMenu: Menubar | undefined;
    @ViewChild('languageMenu') languageMenu: LanguageDropdownComponent | undefined;
    searchText: string = "";
    environment = environment;
    activePage$: Observable<string>;

    constructor(
        private translate: TranslateModule,
        private ts: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // watch active page
        this.activePage$ = this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => route),
        map(route => {
            while (route.firstChild) {
            route = route.firstChild;
            }
            return route;
        }),
        mergeMap(route => route.data),
            map((data) => {
                const section = data.hasOwnProperty('breadcrumb') ? data['breadcrumb'] : (data.hasOwnProperty('section') ? data['section'] : 'home-breadcrumb');
                return 'menu.' + section;
            })
        );
    }

    ngOnInit() {
        this.ts.onLangChange.subscribe((event: LangChangeEvent) => {
            console.log("Language changed: " + event.lang);
            this.loadMenuItems(event.lang);
            this.languageMenu?.setLang(event.lang);
            this.selectedLang = event.lang;
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.languageMenu?.setCountries([
                { id: 'cs', code: 'CZ', name: 'Čeština' },
                { id: 'en', code: 'GB', name: 'English' },
                { id: 'de', code: 'DE', name: 'Deutsch' },
                { id: 'km', code: 'KH', name: 'Khmer' },
            ]);
            this.languageMenu?.setLang(this.ts.currentLang);
        });
    }    

    loadMenuItems(lang: string) {
        this.items = [
            {
                label:this.ts.instant('menu.home'),
                icon:'pi pi-fw pi-home',
                url:'/',
                target: '_self',
            },
            {
                label:this.ts.instant('menu.portfolio'),
                icon:'pi pi-fw pi-objects-column',
                items: [
                    {
                        label:this.ts.instant('menu.photo'),
                        icon:'pi pi-fw pi-camera',
                        url:'/photo',
                        target: '_self',
                    },
                    {
                        label:this.ts.instant('menu.painting'),
                        icon:'pi pi-fw pi-palette',
                        url:'/painting',
                        target: '_self',
                    },
                    {
                        label:this.ts.instant('menu.installation'),
                        icon:'pi pi-fw pi-images',
                        url:'/installation',
                        target: '_self',
                    },
                ],
            },
            {
                label:this.ts.instant('menu.exhibition'),
                icon:'pi pi-fw pi-building-columns',
                url:'/exhibition',
                target: '_self',
            },
            /* TODO - remove this DEVEL conditon */
            ...insertIf(!isDevMode(),
            {
                label:this.ts.instant('menu.bio'),
                icon:'pi pi-fw pi-user',
                url:'/bio',
                target: '_self',
            },
            {
                label:this.ts.instant('menu.contact'),
                icon:'pi pi-fw pi-at',
                url:'/contact',
                target: '_self',
            }
            ),
            /* end of TODO */
            /* TODO - remove this - only DEVEL menu */
            ...insertIf(isDevMode(),
            {
                label:"Demo",
                icon:'pi pi-fw pi-sitemap',
                url:'/demo',
                target: '_self',
            },
            {
                label:"Fonts",
                icon:'pi pi-fw pi-language',
                url:'/fonty',
                target: '_self',
            }
            ),
            /* end of TODO */
        ];
    }

    clickOut(event : Event) {
        console.log("clicked outside");

        const baricon = this.mainMenu?.el.nativeElement.querySelector(".p-menubar-button");
        const submenu = this.mainMenu?.el.nativeElement.querySelector(".p-menubar-root-list");
        const clickedInBaricon = baricon && baricon.contains(event.target);
        const clickedInSubmenu = submenu && submenu.contains(event.target);
        const clickedInside = clickedInBaricon || clickedInSubmenu;
        if (!clickedInside) {
            if (this.mainMenu?.mobileActive) {
                console.log("mobileActive closing");
                this.mainMenu.mobileActive = false;
            }
        } else {
            console.log("clicked inside");
        }
    }

    searchFor() {
        console.log("Search for: " + this.searchText);
        var uri = '/search/' + encodeURIComponent(this.searchText);
        window.open(uri, '_blank');
    }
}
