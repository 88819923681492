<div class="post-text" *ngIf="data.text" [ngClass]="getTextClass()">
  <div class="text" *ngIf="data.text | multilang" (click)="toggle()" [style]="getTextStyle()">
    <div class="text-outside">
      <div class="text-inside" [innerHTML]="data.text | multilang">
      </div>
    </div>
  </div>
  <div class="expander" (click)="toggle(true)" *ngIf="!alwaysExpanded()">
    <div class="expander-line-outer">
      <div class="expander-line">
        <span *ngIf="collapsed">
          <i class='pi pi-fw pi-caret-down'></i>
          <i class='pi pi-fw pi-caret-down'></i>
          <i class='pi pi-fw pi-caret-down'></i>
        </span>
        <span *ngIf="!collapsed">
          <i class='pi pi-fw pi-caret-up'></i>
          <i class='pi pi-fw pi-caret-up'></i>
          <i class='pi pi-fw pi-caret-up'></i>
        </span>
        <span class='width'></span>
      </div>
    </div>
  </div>
</div>
