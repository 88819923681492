import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './page-footer.component.html',
    styleUrl: './page-footer.component.scss',
})
export class PageFooterComponent {
}
