import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

import { MainMenuComponent } from './main-menu/main-menu.component';
import { PageFooterComponent } from './page-footer/page-footer.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, MainMenuComponent, PageFooterComponent],
    templateUrl: './app.component.html',
    styles: `
     ::ng-deep body {
        margin: 0;
        padding: 0;
    }
    `,
})
export class AppComponent {
    constructor(private translate: TranslateService) {
        let lang = localStorage.getItem('language');
        if (!lang) {
            lang = navigator.language.substring(0, 2);
            localStorage.setItem('language', lang);
        }
        translate.setDefaultLang(lang);
        translate.use(lang);
    }
}
