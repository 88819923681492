import { Component, ElementRef } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { environment } from '../../environments/environment';
import { ButtonModule } from 'primeng/button';
import { TranslateService } from '@ngx-translate/core';

export let counter: number = 0;

@Component({
    selector: 'rakosnicek',
    standalone: true,
    imports: [ButtonModule],
    templateUrl: './rakosnicek.html',
    styleUrl: './rakosnicek.scss',
    animations: [
        trigger('rakosnicekOpacity', [
            state('rakosnicekBezbarvy',  style({ opacity: 0.17, })),
            state('rakosnicekPruhledny', style({ opacity: 0.44, })),
            state('rakosnicekBarevny',   style({ opacity: 1 })),
            transition('* => *', animate("987ms ease-in-out"))
        ]),
        trigger('bublinaOpacity', [
            state('bubbleOff',  style({ opacity: 0, transform: "scaleX(0.5)", "letter-spacing": "-0.4em", })),
            state('bubbleOn',   style({ opacity: 1, transform: "scaleX(1.0)", "letter-spacing": "0.17em", })),
            transition('* => *', animate("1920ms ease-in-out"))
        ]),
    ]
})

export class Rakosnicek {
    isTouchDevice = 'ontouchstart' in document.documentElement;
    environment = environment;

    no = counter++;
    state = "rakosnicekBezbarvy";
    bublinaBool = false;
    bublinaState = "rakosnicekBezbarvy";
    bublinaPinned = false;
    rakosnicekClicked = 0;
    hoverMap = false;
    hoverImg = false;
    touchingMap = false;
    touchingImg = false;
    myTimeout : any = null;

    constructor(private el: ElementRef, private translate: TranslateService) {
    }

    setBublinaState(value: boolean): void {
        if (this.bublinaBool != value) {
            this.bublinaBool = value;
            if (value == true) {
                if (this.myTimeout == null && !this.bublinaPinned) {
                    this.myTimeout = setTimeout (() => {
                        this.bublinaPinned = true;
                        this.rakosnicekClicked = 0;
                        this.rakosnicekUpdate();
                    }, 2000);
                }
            } else {
                if (this.myTimeout != null) {
                    let myTimeout = this.myTimeout;
                    this.myTimeout = null;
                    clearTimeout(myTimeout);
                }
            }
        }
        this.bublinaState = (this.bublinaBool || this.bublinaPinned) ? "bubbleOn" : "bubbleOff";
    }
    removeBublina(): void {
        this.bublinaPinned = false;
        this.rakosnicekClicked = 0;
        this.rakosnicekUpdate();
    }
    rakosnicekClick(): void {
        if (this.bublinaPinned) {
            if (this.rakosnicekClicked <= 2) {
                this.rakosnicekClicked++;
                this.rakosnicekUpdate();
            } else {
                this.gotoEart();
            }
        }
    }
    gotoEart(): void {
        window.open("https://epattova.wixsite.com/e-art/", '_blank');
    }

    bublinaMessage(): string | null {
        if (!this.bublinaPinned) {
            return this.translate.instant('websiteMessage_welcome');
        }
        if (this.rakosnicekClicked == 0) {
            return this.translate.instant('websiteMessage_welcome') + "<br>" + this.translate.instant('websiteMessage_welcome2');
        }
        if (this.rakosnicekClicked <= 2) {
            return this.translate.instant('websiteMessage_welcome') + "<br>" + this.translate.instant('websiteMessage_welcome3') + Array(this.rakosnicekClicked+1).join(".");
        }
        return this.translate.instant('websiteMessage_openEart');
    }
    
    rakosnicekUpdate(): void {
        if (this.hoverMap || this.touchingMap || this.rakosnicekClicked >= 3) {
            this.state = "rakosnicekBarevny";
            this.setBublinaState(true);
        }
        else if (this.hoverImg || this.touchingImg || this.rakosnicekClicked >= 2) {
            this.state = "rakosnicekPruhledny";
            this.setBublinaState(false);
        }
        else {
            this.state = "rakosnicekBezbarvy";
            this.setBublinaState(false);
        }
    }

    rakosnicekMove(event: TouchEvent): void {
        const element = document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY);
        if (!this.el.nativeElement) {
            return;
        }
        // touchingMap je true, kdyz je dotyk na rakosnickovi
        // touchingImg je true, kdyz je dotyk na oblasti obrazku
        // jinak vsechno false
        const mapArea = this.el.nativeElement.querySelector('.rakosnicek-area-fg');
        if (mapArea == element) {
            this.touchingMap = true;
        } else {
            this.touchingMap = false;
            const imgArea = this.el.nativeElement.querySelector('.rakosnicek-area-bg');
            this.touchingImg = (imgArea == element);
        }
        this.rakosnicekUpdate();
        event.stopPropagation();
    }
    rakosnicekTouchEnd(event: TouchEvent): void {
        event.stopPropagation();
        this.touchingMap = false;
        this.touchingImg = false;
        this.rakosnicekUpdate();
    }

    rakosnicekHoverImg(event: Event, value: boolean): void {
        if (this.isTouchDevice) {
            return;
        }
        this.hoverImg = value;
        this.rakosnicekUpdate();
    }
    rakosnicekHoverMap(event: Event, value: boolean): void {
        if (this.isTouchDevice) {
            return;
        }
        this.hoverMap = value;
        this.rakosnicekUpdate();
    }
}
