<div class='font-samples-outer'>
  <div class='font-samples-inner'>

    <p-fieldset legend="Font preview" styleClass="font-samples-main">

      <p-card styleClass="font-test font1">
        <p class="fontname">Cormorant</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>
      <p-card styleClass="font-test font2">
        <p class="fontname">Petrona</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>
      <p-card styleClass="font-test font3">
        <p class="fontname">Mate</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>
      <p-card styleClass="font-test font4">
        <p class="fontname">Gilda Display</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>
      <p-card styleClass="font-test font5">
        <p class="fontname">Baskerville</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>
      <p-card styleClass="font-test font6">
        <p class="fontname">Oranienbaum</p>
        <p class="p0" [innerHTML]="text0()"></p>
        <p-divider type="solid"></p-divider>
        <p class="p1" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p2" [innerHTML]="text()"></p>
        <p-divider class="divider-narrow" type="solid"></p-divider>
        <p class="p3" [innerHTML]="text()"></p>
      </p-card>

    </p-fieldset>

  </div>  
</div>