<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" [dropdownIcon]="'none'" (onChange)="onLangChange()" appendTo="self">
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center" *ngIf="selectedCountry">
            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" 
              [class]="'flag flag-' + selectedCountry.code.toLowerCase()"
              title="{{selectedCountry.name}}"
              alt="{{selectedCountry.name}}"
              style="width: 25px"
            />
        </div>
    </ng-template>
    <ng-template let-country pTemplate="item">
        <div class="lang-row">
            <div class="c1">{{ country.name }}</div>
            <img class="c2" src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" [alt]="'flag-' + country.code" style="width: 18px"/>
        </div>
    </ng-template>
</p-dropdown>