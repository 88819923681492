import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

import { MultilangService } from './multilang.service';
import { TransUnit } from './multilang.types';

@Pipe({
  name: 'multilang',
  pure: false // required to update the value when the promise is resolved
})
export class MultilangPipe implements PipeTransform {
  onLangChange: Subscription | undefined;

  constructor(private multilang: MultilangService, private translate: TranslateService, private _ref: ChangeDetectorRef) {
  }

  transform(value: TransUnit | undefined, ...args: unknown[]): string {

    if (value == undefined) {
      return '';
    }

    // if there is a subscription to onLangChange, clean it
    this._dispose();

    // subscribe to onLangChange event, in case the language changes
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        console.log("MultilangPipe: detected lang change: " + event.lang);
        this.multilang.setLang(event.lang);
        this.multilang.getTrans(value as TransUnit);
      });
    }

    return this.multilang.getTrans(value as TransUnit);
  }
  
  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }

  ngOnDestroy(): void {
    this._dispose();
  }
}
