<div class='home-outer'>
  <div class='home-inner'>

    <div class="home-columns">
      <home-card styleClass="home-column" background="/assets/img/bg-section1.jpg" url="/photo">
        <div class="home-label">
          <div>
            {{ 'menu.photo' | translate }}
          </div>
        </div>
      </home-card>
      <home-card styleClass="home-column" background="/assets/img/bg-section2.jpg" url="/painting">
        <div class="home-label bottom">
          <div>
            {{ 'menu.painting' | translate }}
          </div>
        </div>
      </home-card>
      <home-card styleClass="home-column" background="/assets/img/bg-section3.jpg" url="/installation">
        <div class="home-label">
          <div>
            {{ 'menu.installation' | translate }}
          </div>
        </div>
      </home-card>
      <home-card styleClass="home-column" background="/assets/img/bg-section4.jpg" url="/exhibition">
        <div class="home-label bottom">
          <div>
            {{ 'menu.exhibition' | translate }}
          </div>
        </div>
      </home-card>
    </div>

  </div>
</div>
