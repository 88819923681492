<div class='content-page-outer'>
  <div class='content-page-inner'>

    <p-scrollTop behavior="smooth" title="{{'scroll-to-top' | translate}}" icon="pi pi-arrow-up"></p-scrollTop>

    <div class="content-header">{{ 'menu.'+section | translate }}</div>

    <div class="content-page-posts"
        *ngIf="paginator$ | async as paginator"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="loadMoreProducts(paginator)">

      <div *ngFor="let post of paginator.items">

        <div class="web-post web-post-{{post.type}}">

          <div class="post-title">{{ (post.date ? (post.date+' – ') : '') + (post.title | multilang) }}</div>
          
          <div *ngIf="post.type=='message'">
            <message-card [setData]="$any(post.content)"></message-card>
          </div>
          <div *ngIf="post.type=='photo'">
            <photo-card [setData]="$any(post.content)"></photo-card>
          </div>
          <div *ngIf="post.type=='gallery'">
            <gallery-card [setData]="$any(post.content)"></gallery-card>
          </div>
          <div *ngIf="post.type=='video'">
            <video-card [setData]="$any(post.content)"></video-card>
          </div>

        </div>

      </div>

      <div class="content-loader-box" *ngIf="!paginator.hasMorePages">
        <div style="flex-flow: row; margin-top: 1rem;">
          <i class="pi pi-stop" style="font-size: 1rem;"></i>
          <p class="text-center">{{ 'content.loader-end-of-page' | translate }}</p>
        </div>
      </div>
    </div>

    <div styleClass="content-page-loader" *ngIf="loading$ | async">
      <div class="content-loader-box">
        <div>
          <i class="pi pi-spinner pi-spin" style="font-size: 2.3rem;"></i>
          <p class="text-load-more text-center">{{ 'content.loader-load-more' | translate }}</p>
        </div>
      </div>
    </div>

  </div>  
</div>  
