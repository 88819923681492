import { WebPost } from '../models/models';
import { htmlLink } from './models';
import * as Models from '../models/models';

export const pageItemsPhoto: WebPost[] = [
            {
                title: {
                    en: "Bohemian Paradise",
                    cs: "Rájcování",
                    de: "Böhmischer Paradiesrausch",
                },
                date: "2015",
                type: 'gallery',
                content: {
                    text: {
                        en: "<i>50x60 cm silver-gelatin print with a drawing on the negative</i>" + "<br>"
                        + "I photographed a landscape - the rocks around me - from the summits of sandstone towers with a view camera in Czech Paradise :-)" 
                        + " It was a long process of carrying a heavy camera onto the towers, but firstly full of strong experiences, which I have all the time"
                        + " by climbing. I am trying to intervene these to you through my collection and I don't think I am done with it... maybe you'll see a continuation... Edita"
                        + "<br>"
                        + "Her collection called 'Bohemian Paradise' brought us joke and hyperbole in the form of 'dialog' between unseen climbers and rocks on whose summits they climb."
                        + " Rough humor of tough climbers is mixed with fantasized answers and sayings of the rock towers on which they climb."
                        + "<br>" + "Shortened from: Richard Konicek " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                        cs: "<i>klasické fotografie v rámu 50x60 cm - s kresbou na negativu</i>" + "<br>"
                        + 'Fotografovala jsem krajinu - okolní skály - Sinarem z vrcholků pískovcových věží na Hrubé Skále a v Ádršpachu :-) Jednalo se'
                        + ' o dlouhý proces tahání těžkého aparátu na věže, hlavně však o silné zážitky, které mám při lezení vždy.' 
                        + ' Snažím se vám je tímto souborem zprostředkovat a ještě ho určitě nepovažuji za ukončený...třeba se dočkáte pokračování ... Edita' 
                        + "<br>"
                        + 'Cyklus nazvaný "Rájcování" přinesl vtip a nadsázku, ve formě "dialogu" neviděných horolezců a skal, na jejichž vrcholy se derou.' 
                        + ' Drsný humor tvrdých lezců se mísí s fantazijními odpověďmi a průpovídkami slézaných skal.' 
                        + "<br>" + "Zkráceno dle: Richarda Koníčka " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                        de: "<i>50x60 cm, Fotografie im Rahmen - die Zeichnung ans Negativbild</i>" + "<br>"
                        + 'Ich stelle hier Fotos von meiner neuer Kollektion "Böhmischer Paradiesrausch" aus. Ich habe sie von Gipfeln der Sandsteinfelsen im Böhmischen Paradies mit Fotoapparat Sinar fotografiert.' 
                        + "<br>"
                        + 'Zyklus "Böhmischer Paradiesrausch" brachte Witz und Übertreibung in der "Dialogform" zwischen ungesehenen Kletterern und Felsen, auf ihre Gipfel sie klettern.' 
                        + ' Rauer Humor harter Kletterer wird mit fantasierten Antworten und Sprüchen gekletterten Felsen gemischt.' 
                        + "<br>" + "Verkürzt nach: Richard Konicek " + htmlLink("https://www.www-kulturaok-eu.cz/news/osten-opet-v-galerii-kritiku-aneb-to-nejlepsi-z-42-mezinarodniho-bienale-kresby-skopje-2014/", "OSTEN opět v Galerii kritiků aneb to nejlepší z 42. Mezinárodního bienále kresby Skopje 2014"),
                    },
                    
                    style: Models.GALLERY_INDICATORS | Models.AUTO_ASPECT,
                    items: [
                        {
                            title: 'Ádršpach',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Adr1.jpg',
                        },
                        {
                            title: 'Ádršpach',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Adr2.jpg',
                        },
                        {
                            title: 'Hrubá Skála',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/hrubice.jpg',
                        },
                        {
                            title: 'Ádršpach',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/ja a Starostova.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj10.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj11.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj12.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj13.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj14.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj15.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj16.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj17.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj18.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj19.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj2.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj20.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj21.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj22.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj23.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj24.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj3.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj4.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj5.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj6.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj7.jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj8 (2).jpg',
                        },
                        {
                            title: 'Český Ráj',
                            description: '-',
                            src: '/assets/img/Photos/Rajcovani_2015/Raj8.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    en: "Alteration of Function of Vehicle",
                    cs: "Změna účelu vozidla",
                    de: "Veränderung des Fahrzeugzwecks",
                },
                
                date: "2010",
                type: 'gallery',
                content: {
                    text: {
                        en: "<i>photographs 60x50 cm</i>" + "<br>"
                        + "In Czech Republic there are two possibilities how to unregister a vehicle. First option is to get a confirmation of “ecological disposal”." 
                        + " Second is called: “Change of the vehicle purpose”, this way it is possible to unregister a vehicle, if one proves, that it changed"
                        + " its original purpose. Therefore people usually bring to the authority photographs of the wreck, which is used as a hen house"
                        + " or as a kennel or something similar. I decided to transform the wreck into an artwork."
                        + "<br>"
                        + "I photographed with a large format camera, through the wreck window every Sunday on one slide film." 
                        + " Onto these slides I scratched the landscape from the previous photographs, because now I would be happy if I could drive through the same land again.",
                        cs: "<i>fotografie 60x50 cm</i>" + "<br>"
                        + 'Když se chcete zbavit vraku potřebujete papír o „ekologické likvidaci”. Existuje zákon s názvem „Změna účelu vozidla”. Díky tomu je možné auto odhlásit, pokud člověk prokáže, že změnilo účel.' 
                        + ' K tomu obvykle lidé nosí na úřad fotografie, na nichž je vrak, který slouží jako kurník nebo psí bouda a podobně. Já jsem se rozhodla přetvořit vrak na umělecké dílo.' 
                        + "<br>"
                        + 'Fotila jsem skrz vrak každou neděli. Všechny fotky nejsou stejné, protože jsem zkoušela díky použití starého Sinaru různé roviny ostrosti, také se měnilo trochu okolí, i když vrak stojí stále na stejném místě.' 
                        + ' Do těchto diapozitivů jsem vyryla krajinu, kterou jsem předtím denně jezdila, ale teď už nemůžu. ',
                        de: "<i>Fotografie 60x50 cm</i>" + "<br>"
                        + 'Wenn man das Autowrack von der Evidenz herausnehmen will, muss man die Bestätigung über ökologische Liquidation vorlegen.' 
                        + ' Es gibt zweite Möglichkeit im Dekret “Veränderung des Fahrzeugzwecks”.  Dazu bringt man gewöhnlich zum Amt Fotos, dass das Wrack wie Hühnerstall, Hundehütte, Werbeanzeiger' 
                        + ' und dergleichen dient. Ich entschied mich aus manchen Wrackteilen ein Kunstwerk zu schaffen.' 
                        + "<br>"
                        + 'Ich fotographierte jeden Sonntag durch die Wrackfensterscheibe. Ich benutzte den alten Fotoapparat SINAR und probierte dabei verschiedene Niveaus der Schärfe, deshalb sind nicht alle Bilder die gleichen.' 
                        + ' Auch das Wetter und die Umgebung änderten sich, obwohl das Wrack auf dem gleichen Ort stand. Auf diese Diapositive ritzte ich mit dem Stichel das Land, durch das ich vorher täglich fuhr, aber jetzt kann ich nicht mehr.', 
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: 'Nový data',
                            description: 'Hodně dat',
                            src: '/assets/img/Photos/Change_foto_2010/f_change1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Change_foto_2010/f_change7.jpg',
                        },
                    ],
                },
            },

            {
                title: {
                    en: "Autothoughts",
                    cs: "Automyšlenky",
                    de: "Autogedanken",
                },
                
                date: "2009",
                type: 'gallery',
                content: {
                    text: {
                        en: "<i>50x60 cm silver-gelatin print with a drawing on the negative</i>" + "<br>"
                        + "I photographed the landscape where I drove everyday there and back. I took pictures with a large format camera on black and white film" 
                        + " through the window by the left side of the driver, because I wanted the shot look the same as a driver sees it. Than I drew"
                        + " on the negative with a permanent marker where I would rather be or what I was thinking about by driving through these places.",
                        cs: "<i>klasické fotografie 50x60 cm - s kresbou na negativu</i>" + "<br>"
                        + 'Fotografovala jsem krajinu, kterou denně jezdím tam a zpět. Můj klasický Sinar jsem postavila k okénku u řidiče, aby záběr vypadal'
                        + ' co nejvíc tak, jak ho člověk vidí při řízení. Poté jsem do negativu fixou nakreslila kde bych radši byla nebo na co jsem myslela'
                        + ' při projíždění stále stejnými místy.',
                        de: "<i>Fotografie im Rahmen 50 x 60 cm- die Zeichnung ans Negativbild</i>" + "<br>"
                        + 'Ich fotografierte das Land, wo ich jeden Tag mit dem Auto fuhr. Die Bilder machte ich mit dem Fotoapparat Sinar auf das schwarz-weisse' 
                        + ' Negativ durch die Fensterscheibe an der Lenkertür. Danach zeichnete ich mit dem Filzstift in der Linie auf die Negative,'
                        + ' woran ich dachte, oder wo ich sein wollte.', 
                    },
                    style: Models.GALLERY_PREVIEW | Models.GALLERY_TITLE,
                    items: [
                        {
                            title: 'Nový..',
                            description: 'Tadatatata',
                            src: '/assets/img/Photos/Automyslenky_2009/bar u dalnice u Ritky.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/Bauhaus a sroubovaky copy.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/cesta na Ritku a Pup na kole.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/hovno na okne v Modranech a lyzar copy.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/Lahovicky most a carodejnice .jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/okruh a skok padakem copy.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/Sinar na nabrezi.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/stafle a nas dum.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/v Radotine u okruhu.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Automyslenky_2009/Zizkovska rozhledna a pipik.jpg',
                        },
                    ],
                },
            },
            
            {
                title: {
                    en: "Mexico",
                    cs: "Mexiko",
                    de: "Mexiko",
                },
                
                date: "2006",
                type: 'gallery',
                content: {
                    text: {
                        en: "<i>photographic emulsion on clothes</i>" + "<br>"
                        + "This project is about the journey and experience absorbed into ones skin and clothes in the form of dirtiness." 
                        + " It is a pity to wash all these experiences straight away once you are back home and damage this way physical record of their existence." 
                        + " Therefore I enlarged photos from Mexico with the use of photographic emulsion on the already mentioned outfits. With the use"
                        + " of this technique I made an artpiece for an album out of them, because it is not possible to wash it or wear it anymore."
                        + " In the photographs are shots from demonstrations in Oaxaca and some are from Chichen Itzá.",
                        cs: "<i>fotografická emulze na oblečení</i>" + "<br>"
                        + 'Tento projekt je o cestě a zážitcích, které se člověku vpijí do kůže a hlavně do oblečení v podobě špíny.' 
                        + ' Je škoda po návratu domů tyto zážitky rychle vyprat a tím popřít fyzický doklad o jejich existenci. Proto jsem nazvětšovala fotky z Mexika pomocí fotoemulze'
                        + ' na již zmíněné hadříky. Tímto ručním postupem z nich vzniklo umělecké dílo do alba, protože již není možné je vyprat ani nosit.'
                        + ' Na prvních fotografiích jsou záběry z demonstrací v Oaxace, na posledních dvou z Chichen Itzá.',
                        de: "<i>Fotografie auf Kleidung</i>" + "<br>"
                        + 'Dieses Projekt ist über Reise und Erlebnisse, die in die Haut und Kleidung wie Schmutz gehen. Es ist Schade, diese Erlebnisse'
                        + ' nach der Heimkehr schnell auszuwaschen und damit ein physisches Dokument über ihre Existenz zu bestreiten.' 
                        + ' Deshalb vergrößerte ich die Fotos aus Mexiko mithilfe der Emulsion auf schon erwähnte Kleidung. Mit diesem Arbeitsverfahren entstand'
                        + ' ein Kunstwerk ins Fotoalbum. Es ist schon nicht möglich zu waschen und zu tragen.'
                        + ' Auf den ersten Fotografien sind Aufnahmen aus Demonstrationen in Oaxaca und zwei letzten sind aus Chichen Itzá.',
                    },
                    style: Models.GALLERY_INDICATORS,
                    items: [
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex5.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex6.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex7.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Mexiko_2006/mex8.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    en: "Bugs on the Fair",
                    cs: "Broučci na pouti",
                    de: "Kleine Käfer bei Kirchweihfest",
                },
                
                date: "2003",
                type: 'gallery',
                content: {
                    text: {
                        en: "<i>photograph</i>" + "<br>"
                        + "These are the emotions from the fair, inspired by my favourite book from my childhood. In fact there are beetle puppets, so the hand makes an impression of the body with heads or hands made out of clay."
                        + " I work with memory of the childhood and I try to arise the observer's imagination.",
                        cs: "<i>fotografie</i>" + "<br>"
                        + 'Soubor se skládá z barevných fotografií. Jsou to dojmy z pouti inspirované mou oblíbenou knížkou z dětství. Jsou to vlastně broučí maňásci, takže ruka vytváří dojem těla s hlavičkami či ručičkami z plastelíny.' 
                        + ' Je to vzpomínka na dětství a pokus o rozpoutání divákovy představivosti.',
                        de: "<i>Fotografie</i>" + "<br>"
                        + 'Der Zyklus besteht aus Farbfotografien. Es handelt sich um Eindrücke von einer Kirchweih, inspiert durch das Lieblingsbuch'
                        + ' meiner Kindheit. Es sind eigentlich Käfer-handpuppen, das heißt, dass die Hand den Eindruck weckt, dass es sich um einen Körper'
                        + ' mit Köpfen oder Händen aus Plastiline handelt. Es ist eine Erinnerung an die Kindheit und ein Versuch, die Phantasie des Zuschauers zu erwecken.',
                    },
                    style: Models.GALLERY_BOXES_2,
                    items: [
                        {
                            title: 'Nový data',
                            description: 'Hodně dat',
                            src: '/assets/img/Photos/Broucci_2004/broučci_na_pouti_1.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Broucci_2004/broučci_na_pouti_2.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Broucci_2004/broučci_na_pouti_3.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Broucci_2004/broučci_na_pouti_4.jpg',
                        },
                        {
                            title: '---',
                            description: '-',
                            src: '/assets/img/Photos/Broucci_2004/broučci_na_pouti_5.jpg',
                        },
                    ],
                },
            },
            {
                title: {
                    en: "Dream",
                    cs: "Sen",
                    de: "Ein Traum",
                },
                
                date: "2007",
                type: 'photo',
                content: {
                    description: {
                        en: "<i>photographic emulsion on canvas 55 x 55 cm</i>" + "<br>"
                        + "These are random pictures originate in experiments with photographic emulsion. The canvases have not turned into black squares," 
                        + " but out of the picture peers some grotesque animal, which wipes out boundary between conscious and unconscious." 
                        + " Similarly to the morning after a party.",
                        cs: "<i>fotografická emulze na plátně 55x55 cm</i>" + "<br>"
                        + 'Jedná se o náhodné obrazy vzniklé experimentováním s fotoemulzí. Z pláten nevznikly černé čtverce,' 
                        + ' ale z obrazu vykukuje jakési podivné zvíře, které stírá hranice mezi vědomím a  nevědomím. Podobně jako ráno po párty.',
                        de: "<i>Fotografien auf der Leinwand 55x55 cm</i>" + "<br>"
                        + 'Es handelt sich um Zufallsbilder, die mithilfe der Experimentierung mit Fotoemulsion entstanden. Aus den Leinwänden wurden'
                        + ' keine schwarzen Vierecke, aber aus dem Bild guckt ein seltsames Tier vor, das die Grenze zwischen dem Bewusstsein und Unbewusstsein verwischt.' 
                        + ' Ähnlich wie am Morgen nach einer Party, wenn man aufwacht.'
                    },
                    title: {
                        en: "Dream",
                        cs: "Sen",
                        de: "Ein Traum",
                    },
                    src: '/assets/img/Photos/Sen_2007/Nsen.jpg',
                },
            },
            {
                title: {
                    en: "Survival",
                    cs: "Přežít",
                    de: "Survival",
                },
                
                date: "2007",
                type: 'photo',
                content: {
                    description: {
                        en: "<i>photographic emulsion on canvas 55 x 55 cm</i>" + "<br>"
                        + "On a trip in The Cairngorms in Scotland I took only these two shots, because it was so cold there, that I could not use fingers"
                        + " on my hand properly. I enlarged it with the use of photographic emulsion on canvas and I further boosted their imperfections," 
                        + " because it exactly embodies what I had in mind during a long freezing night without sleep. Warning: do not go to Scotland"
                        + " with a cheap summer sleeping bag.",
                        cs: "<i>fotografická emulze na plátně 55x55 cm</i>" + "<br>"
                        + 'Z výletu v The Cairngorms ve Skotsku mám pouze tyto dvě fotografie, protože mi tam byla taková zima, že jsem nemohla dost dobře hýbat prsty na rukou.' 
                        + ' Nazvětšováním pomocí fotoemulze na plátně jsem ještě podpořila jejich nedokonalost, ta totiž nejlépe vyjadřuje co se člověku honí hlavou při dlouhé mrazivé noci bez spánku.' 
                        + ' Poučení: neberte si do Skotska levný letní spacák.',
                        de: "<i>Fotografien auf der Leinwand 55x55 cm</i>" + "<br>"
                        + 'Aus der Ausflug in „The Cairngorms“ in Schottland habe ich nur diese zwei Fotografien, weil es mir dort so kalt war, dass ich nicht'
                        + ' ganz gut mit den Fingern bewegen konnte. Die Vergrößerung mit Fotoemulsion auf der Leinwand unterstützte noch'
                        + ' ihre Unvollkommenheit. Sie äußert am besten, was man bei einer langen frostigen schlaflosen Nacht im Kopf jagt.'
                        + ' Die Lehre: keinen billigen Sommerschlafsack nach Schottland nehmen.'
                    },
                    title: {
                        en: "Survival",
                        cs: "Přežít",
                        de: "Survival",
                    },
                    src: '/assets/img/Photos/Smrt_2007/Nsmrt.jpg',
                },
            },
];
